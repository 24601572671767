import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const UnverifiedToken = ({visibility, dialogTitle, dialogMessage}) => {
  const navigate = useNavigate()
  
  const userLogout = () => {
    setTimeout(() => {
        localStorage.removeItem('accessToken')
        navigate('/')
    }, 200)
  }

  const footerDialogError = (
    <div className='text-center mt-0'>
      <Button label="Logout" severity='danger' onClick={() => userLogout()} autoFocus />
    </div>
  )

  return (
    <Dialog visible={visibility} header={dialogTitle} footer={footerDialogError}
        onHide={() => visibility = false} style={{width:'30rem', margin:'10px'}}>
        <div className='text-center mb-0'>
            <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '6rem', color: 'red' }} />
            <p style={{ fontSize: '18px'}}>{dialogMessage}</p>
        </div>
    </Dialog>
  )
}

export default UnverifiedToken
