import React, { useEffect, useState } from 'react'
import styles from './RevenueHistory.module.css'
import Page from '../../Layouts/Page/Page'
import { Card } from 'react-bootstrap'
import Loading from '../../components/Loading/Loading'
import { BASE_API_URL, formattedDateWithOutTime, getSeverity, formatIDRCurrency, formatMonth } from '../../helpers'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import { Tag } from 'primereact/tag'
import { InputText } from 'primereact/inputtext'

const RevenueHistory = () => {
  const [ listSalaryReport, setListSalaryReport ] = useState(null)
  const [ detailTeacher, setDetailTeacher ] = useState(null)
  const [ detailSession, setDetailSession ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const id = localStorage.getItem("teacherID")
  const navigate = useNavigate()
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getListSalaryReport =async () => {
    setLoading(true)

    const getListSalaryOption = {
        method: 'post',
        url: `${BASE_API_URL}/teacher-salary`,
        headers: {
            'Authorization': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        },
        data: {
            idTeacher: id
        }
    }

    await axios.request(getListSalaryOption)
      .then((response) => {
        const salaryReport = response.data.listGaji

        salaryReport.map((report, index) => {
            report["index"] = index+1
            report["bulanGaji"] = formattedDateWithOutTime(report.bulanGaji)
        })

        setListSalaryReport(response.data.listGaji.filter((data) => data.statusGaji == "Terbayar"))
        setDetailTeacher(response.data.guru)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }
  
  useEffect(() => {
    getListSalaryReport()
  }, [])

  const headerListsalaryReport = () => (
    <div>
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
                <p className={styles.cardTitle}>Daftar Gaji</p>
            </div>
            <div>
                <span className='p-input-icon-left'>
                    <i className='pi pi-search'></i>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search"/>
                </span>
            </div>
        </div>
    </div>
  )

  const actionBodyTemplate = (rowData) => {
    return (
        <Button label='Slip' icon='pi pi-file-pdf' className='ml-2' severity='danger' style={{borderRadius: '10px', paddingBlock: '7px'}} onClick={() => {
            navigate('/report-salary', {state: {sesi: modifyDetailSession(rowData), guru: detailTeacher}})
        }}/>
    )
  }

  const modifyDetailSession = (data) => {
    const listResult = []

    if (data !== null && data.sesi.length > 0) {
        const sesi = data.sesi
        sesi.map((detailData) => {
            const kelas = detailData.kelas
            const subjekKelas = `${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl.${kelas.level}`
            const listSession = []
            const bulanGaji =  formatMonth(data.bulanGaji)
            const totalGaji = data.jumlahGaji

    
            if (detailData.indexSesi.length > 0) {
                const newIndexSesi = detailData.indexSesi.split(",")
                
                newIndexSesi.map((index) => {
                    listSession.push(parseInt(index) + 1)
                })
            }
    
            const formatData = {
                _id: detailData._id,
                subjectClass: subjekKelas,
                qtySession: detailData.jumlahSesi,
                listSession: listSession,
                bulanGaji: bulanGaji,
                nominal: data.jumlahGaji / data.jumlahSesi * detailData.jumlahSesi,
                totalGaji: totalGaji
            }
    
            listResult.push(formatData)
        }) 
    }

    setDetailSession(listResult)
    return listResult
  }

  const bodyCurrencyTemplate = (rowData) => {
    return formatIDRCurrency(rowData)
}

  const bodyStatusTemplate = (rowData) => {
    return (
        <Tag value={rowData} severity={getSeverity(rowData, "Salary")}></Tag>
    )
  }

  return (
    <Page>
        <Card>
            <Card.Body>
                <DataTable value={listSalaryReport} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                  rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} scrollable header={headerListsalaryReport}
                  globalFilterFields={['jumlahGaji', 'dibayar', 'statusGaji', 'bulanGaji', 'jumlahSesi']}
                >
                    <Column field='index' header="No"></Column>
                    <Column field='bulanGaji' header="Bulan Gaji" body={(row) => formatMonth(row.bulanGaji)} sortable style={{minWidth: '12rem'}}></Column>
                    <Column field='jumlahSesi' header="Qty Sesi" sortable style={{minWidth: '8rem'}}></Column>
                    <Column field='jumlahGaji' header="Nominal Gaji" body={(row) => bodyCurrencyTemplate(row.jumlahGaji)} style={{minWidth: '12rem'}}></Column>
                    <Column field='statusGaji' header="Status" body={(row) => bodyStatusTemplate(row.statusGaji)} style={{minWidth: '10rem'}}></Column>
                    <Column body={actionBodyTemplate} style={{minWidth: '17rem'}}></Column>
                </DataTable>
            </Card.Body>
        </Card>

        <Loading visible={loading}/>
    </Page>
  )
}

export default RevenueHistory
