import React, { useEffect, useState } from 'react'
import Page from '../../Layouts/Page/Page'
import styles from './Dashboard.module.css'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { IconEdufic } from '../../assets'
import { BASE_API_URL, formatIDRCurrency, formatMonth, formattedDateWithDay, formattedDateWithOutTime, getSeverity, stringToDate } from '../../helpers'
import axios from 'axios'
import { formattedDate } from '../../helpers'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { listFinanceAccount } from '../../helpers/listBank'
import { Dropdown } from 'primereact/dropdown'
import { Tag } from 'primereact/tag'
import { DataTable } from 'primereact/datatable'
import Loading from '../../components/Loading/Loading'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'



const Dashboard = () => {
  const teacherID = localStorage.getItem('teacherID')
  const teacherNickName = localStorage.getItem('teacherNickName')
  const teacherName = localStorage.getItem('teacherName')
  const teacherAddress = localStorage.getItem('teacherAddress')
  const teacherEducation = localStorage.getItem('teacherEducation')
  const teacherPhone = localStorage.getItem('teacherPhone')
  const teacherTTL = `${localStorage.getItem('teacherBornPlace')}, ${localStorage.getItem('teacherBornDate')}`
  const teacherBornDate = localStorage.getItem('teacherBornDate')
  const teacherBornPlace = localStorage.getItem('teacherBornPlace')
  const teacherBank = localStorage.getItem('teacherBank')
  const teacherBankAccount = localStorage.getItem('teacherBankAccount')
  const teacherNameBankAccount = localStorage.getItem('teacherNameBankAccount')
  const teacherEmail = localStorage.getItem('teacherEmail')
  const teacherClassList = localStorage.getItem('teacherClassList')
  const [ listKelas, setListKelas] = useState({})
  const [ warningDialog, setWarningDialog ] = useState(false)
  const [ dialogUpdateProfile, setDialogUpdateProfile ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ listDelayedClass, setListDelayedClass ] = useState([])
  const [ latestMonthSalary, setLatestMonthSalary] = useState({
    month: null,
    currency: null,
    status: null
  })
  const [ detailProfile, setDetailProfile ] = useState({
    _id: teacherID,
    nama: teacherName,
    panggilan: teacherNickName,
    nomor: teacherPhone,
    tempatLahir: teacherBornPlace,
    tanggalLahir: stringToDate(teacherBornDate),
    domisili: teacherAddress,
    pendidikan: teacherEducation,
    namaBank: teacherBank,
    rekening: teacherBankAccount,
    email: teacherEmail,
  })
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const userProfileChange = (event) => {
    const { name, value } = event.target

    setDetailProfile((currentProfile) => ({
      ...currentProfile,
      [name]: value
    }))
  }

  useEffect( () => {
    const getAllTeacherClass = async (data) => {
      // const dataKelas = JSON.stringify({
      //   idKelas: data
      // })

      const options = {
        method: 'post',
        url: `${BASE_API_URL}/today-class-teacher/${localStorage.getItem("teacherID")}`,
        headers: {
          'Content-Type': 'application/json',
        },
      }
  
      await axios.request(options)
        .then((response) => {
          const listClass = response.data
          setListKelas(listClass)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const getDetailSalary = async () => {
      const options = {
        method: 'post',
        url: `${BASE_API_URL}/teacher-salary`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          idTeacher: teacherID
        }
      }
  
      await axios.request(options)
        .then((response) => {
          const listGaji = response.data.listGaji
          const currentSalary = listGaji[listGaji.length-1]

          setLatestMonthSalary(
            {
              month: formatMonth(currentSalary.bulanGaji),
              currency: currentSalary.jumlahGaji,
              status: currentSalary.statusGaji
            }
          )
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const getDelayedClass = async () => {
      setLoading(true)
      const optionsDelayedClass = {
        method: 'get',
        url: `${BASE_API_URL}/check-delay-class/${teacherID}`,
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }
      }

      await axios.request(optionsDelayedClass)
        .then((response) => {
          response.data.delayedClass.map((kelas, index) => {
            kelas['index'] = index + 1
          })

          setListDelayedClass(response.data)
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      setLoading(false)
    }

    getDelayedClass()
    getDetailSalary()
    getAllTeacherClass(teacherClassList)
  }, [detailProfile])

  const showWarningVerifDialog = (data) => {
    if (data.statusKelas == "Belum terverifikasi") setWarningDialog(true)
    else setWarningDialog(false)
  }

  const cardClass = (data) => {
    return (
      <a href={data.statusKelas == "Belum terverifikasi" ? '#' : `/class-management/${data._id}`} style={{textDecoration: 'none'}} key={data._id} className={styles.cardClass}>
        <Card key={data._id} onClick={() => showWarningVerifDialog(data)}>
          <Card.Body>
          <h5 className={styles.cardTitle}>{data.namaKelas}</h5>
          <div className={styles.contentInline}>
            <div>
              Jadwal Rilis
              <p className={styles.releaseDate}>{formattedDate(data.jadwal)}</p>
            </div>
            <div className={styles.studentLayout}>
              Siswa
              <div className={styles.studentCount}>
                {data.murid.length}
              </div>
            </div>
          </div>
          </Card.Body>
        </Card>
      </a>
    )
  }

  const cardDelayedClass = (data) => {
    return (
      <a href={`/class-management/${data.kelas}`} style={{textDecoration: 'none'}} key={data._id} className={styles.cardClass}>
        <Card key={data._id} onClick={() => showWarningVerifDialog(data)}>
          <Card.Body>
          <h5 className={styles.cardTitle}>{data.namaKelas}</h5>
          <div className={styles.contentInline}>
            <div className={styles.studentLayout}>
              Sesi
              <div className={styles.studentCount}>
                {data.daftarSesi.sesi}
              </div>
            </div>
            <div>
              Jadwal Sesi
              <p className={styles.releaseDate}>{formattedDate(data.daftarSesi.jadwal)}</p>
            </div>
          </div>
          </Card.Body>
        </Card>
      </a>
    )
  }



  const listCard = (classList) => {
    const data = []
    const dataLength = classList.length;
    for(let i = 0; i < dataLength; i++) {
      data.push(cardClass(classList[i]))
    }
    return data
  }

  const listCardDelayedClass = (dataProcessed) => {
    const data = []
    const delayedClassList = dataProcessed.delayedClass
    
    if (delayedClassList !== undefined && delayedClassList.length > 0) {
      for (const kelas of delayedClassList) {
        data.push(cardDelayedClass(kelas))
      }
    }

    return data
  }

  const actionButtonLoadMore = (listClass) => {
    const myElement = []
    if (listKelas.length > 0) {
      myElement.push(
        <div className={styles.moreClassLayout}>
          <a href="/class-management"><div className={styles.moreClassButton}>Lebih Banyak</div></a>
        </div>
      )
    }
    else {
      myElement.push(
        <div className={styles.undefinedClass}>
          <Card className={styles.undefinedCardLayout}>
            <i className='pi pi-exclamation-circle' style={{fontSize: '5rem', marginBlock: '15px', color: 'red'}}></i>
            <p>Maaf, kamu belum memiliki kelas sama sekali</p>
          </Card>
          <div className={styles.moreClassLayout}>
            <a href="/class-management"><div className={styles.moreClassButton}>Yuk, buat kelas</div></a>
          </div>
        </div>
      )
    }
    
    return myElement
  }

  const footerDialogWarning = (
    <div className='text-center mt-0'>
      <Button label="Ok" onClick={() => setWarningDialog(false)} autoFocus />
    </div>
  )

  const profileDialogFooter = () => (
    <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" outlined onClick={() => setDialogUpdateProfile(false)} className='mr-3'/>
        <Button label="Save" icon="pi pi-check" onClick={() => updateProfile()}/>
    </React.Fragment>
  );

  const updateProfile = async () => {
    setDialogUpdateProfile(false)
    setLoading(true)

    const dataProfile = detailProfile

    const optionsProfileUpdate = {
      method: 'put',
      url: `${BASE_API_URL}/teachers/${teacherID}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataProfile,
    }

    await axios.request(optionsProfileUpdate)
      .then((response) => {
        if (response.status == 200) {
          localStorage.setItem('teacherNickName', response.data.panggilan)
          localStorage.setItem('teacherEmail', response.data.email)
          localStorage.setItem('teacherName', response.data.nama)
          localStorage.setItem('teacherPhone', response.data.nomor)
          localStorage.setItem('teacherBornDate', formattedDateWithOutTime(response.data.tanggalLahir))
          localStorage.setItem('teacherBornPlace', response.data.tempatLahir)
          localStorage.setItem('teacherAddress', response.data.domisili)
          localStorage.setItem('teacherEducation', response.data.pendidikan)
          localStorage.setItem('teacherBank', response.data.namaBank)
          localStorage.setItem('teacherBankAccount', response.data.rekening)
          localStorage.setItem('teacherID', response.data._id)
          localStorage.setItem('teacherClassList', response.data.kelas)
          localStorage.setItem('availableSchedule', response.data.availableSchedule);
        }
      })
      .catch((error) => {
        console.log(error)
      })

      setLoading(false)
  }

  const renderHeader = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
              <p className={styles.cardTitle} style={{color: 'red', fontWeight: 'bold'}}>Kelas Belum Diabsen</p>
              <i className='pi pi-exclamation-circle' style={{color: 'var(--red-500)', fontSize: '1.5rem'}}></i>
            </div>
            <div className={styles.searchBarContent}>
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" className={styles.searchBarInput} />
              </span>
            </div>
        </div>
    );
  };

  const actionBodyTemplate = (rowdata) => (
        <a href={'/class-management/'+rowdata.kelas}>
          <Button icon={"pi pi-eye"} label='Detail' className='ml-2' rounded severity='primary' style={{borderRadius: '10px'}}/>
        </a>
  )

  const scheduleTemplate = (rowdata) => (
    formattedDateWithDay(rowdata)
  )

  return (
    <Page>
        <Dialog visible={warningDialog} header='Peringatan' footer={footerDialogWarning} 
          onHide={() => setWarningDialog(false)} style={{width:'30rem'}}>
            <div className='text-center mb-0'>
                <i className="pi pi-exclamation-circle mb-3" style={{ fontSize: '6rem', color: 'yellow' }} />
                <p style={{ fontSize: '18px'}}>Kelas masih menunggu verifikasi</p>
            </div>
        </Dialog>

        <Dialog visible={dialogUpdateProfile} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
                 onHide={() => setDialogUpdateProfile(false)} header="Update Profile" footer={profileDialogFooter}>
            <div className='flex flex-wrap gap-2 p-fluid mb-3'>
                <div className='flex-auto'>
                    <label htmlFor="nama" className='font-bold block mb-2'>Nama</label>
                    <InputText id='nama' name='nama' value={detailProfile.nama} onChange={userProfileChange} required autoFocus/>
                </div>
                
                <div className='flex-auto'>
                    <label htmlFor="nomor" className='font-bold block mb-2'>Nomor Telepon</label>
                    <InputNumber id='nomor' name='nomor' value={detailProfile.nomor} onValueChange={userProfileChange} required autoFocus useGrouping={false} 
                    minFractionDigits={0} placeholder='62 ...'/>
                </div>

                <div className='flex-auto mb-3'>
                    <label htmlFor="tanggalLahir" className='font-bold block mb-2'>Tempat Tanggal Lahir</label>
                    <div className={styles.ttlField}>
                        <InputText id='tempatLahir' name='tempatLahir' value={detailProfile.tempatLahir} onChange={userProfileChange} className={styles.bornPlace} required autoFocus/>
                        <span className={styles.koma}>,</span>
                        <Calendar id='tanggalLahir' name='tanggalLahir' className='w-full' value={detailProfile.tanggalLahir} onChange={userProfileChange} showIcon dateFormat='dd MM yy'/>
                    </div>
                </div>

                <div className='flex-auto'>
                    <label htmlFor="domisili" className='font-bold block mb-2'>Kota Asal</label>
                    <InputText id='domisili' name='domisili' value={detailProfile.domisili} onChange={userProfileChange} required autoFocus/>
                </div>

                <div className='flex-auto'>
                    <label htmlFor="pendidikan" className='font-bold block mb-2'>Pendidikan</label>
                    <InputText id='pendidikan' name='pendidikan' value={detailProfile.pendidikan} onChange={userProfileChange} className='w-full' 
                    required autoFocus/>
                </div>

                <div className='flex-auto'>
                    <label htmlFor="namaBank" className='font-bold block mb-2'>Nama Bank</label>
                    <Dropdown id='namaBank' name='namaBank' value={detailProfile.namaBank} options={listFinanceAccount} disabled optionValue='name' optionLabel='name'
                    onChange={userProfileChange} className='w-full' placeholder='Pilih Bank/E-Wallet' filter
                    />
                </div>

                <div className='flex-auto'>
                    <label htmlFor="rekening" className='font-bold block mb-2'>Rekening</label>
                    <InputText id='rekening' name='rekening' value={detailProfile.rekening} onChange={(userProfileChange)} disabled required autoFocus/>
                </div>

                <div className='flex-auto'>
                    <label htmlFor="email" className='font-bold block mb-2'>Email</label>
                    <InputText type='email' id='email' name='email' value={detailProfile.email} onChange={(userProfileChange)} disabled required autoFocus/>
                </div>
            </div>    
        </Dialog>

        <Card>
          <Card.Body>
            <div className={styles.menuLayout}>
              <h4>Profile Pengajar</h4>
              <button onClick={() => setDialogUpdateProfile(true)}><i className='pi pi-wrench'></i>Update Profile</button>
            </div>

            <div className={styles.cardProfileLayout}>
              <div className={styles.profileLayout}>
                <Image src={IconEdufic} className={styles.profile} />
                <div className={styles.id}>{teacherEmail}</div>
              </div>
              <div>
                <table className={styles.tableLayout}>
                  <tbody>
                    <tr>
                      <td className={styles.infoTitle}>Nama</td>
                      <td className={styles.separatial}>:</td>
                      <td>{teacherName}</td>
                    </tr>
                    <tr>
                      <td className={styles.infoTitle}>TTL</td>
                      <td className={styles.separatial}>:</td>
                      <td>{teacherTTL}</td>
                    </tr>
                    <tr>
                      <td className={styles.infoTitle}>Kontak</td>
                      <td className={styles.separatial}>:</td>
                      <td>{teacherPhone}</td>
                    </tr>
                    <tr>
                      <td className={styles.infoTitle}>Domisili</td>
                      <td className={styles.separatial}>:</td>
                      <td>{teacherAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className={styles.tableLayout}>
                  <tbody>
                    <tr>
                      <td className={styles.infoTitle}>Pendidikan</td>
                      <td className={styles.separatial}>:</td>
                      <td>{teacherEducation}</td>
                    </tr>
                    <tr>
                      <td className={styles.infoTitle}>No. Rekening</td>
                      <td className={styles.separatial}>:</td>
                      <td>{teacherBankAccount} - {teacherNameBankAccount !== null ? teacherNameBankAccount : teacherName}</td>
                    </tr>
                    <tr>
                      <td className={styles.infoTitle}>Nama Bank</td>
                      <td className={styles.separatial}>:</td>
                      <td>{teacherBank}</td>
                    </tr>
                    <tr>
                      <td className={styles.infoTitle}>Gaji Terakhir</td>
                      <td className={styles.separatial}>:</td>
                      <td>
                        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                          {formatIDRCurrency(latestMonthSalary.currency == null ? 0 : latestMonthSalary.currency)} ({latestMonthSalary.month})
                          <Tag value={latestMonthSalary.status} severity={getSeverity(latestMonthSalary.status, "statusGaji")} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Card.Body>
        </Card>
        <br />

        {listDelayedClass.delayedClass != undefined ? 
          <Card>
            <Card.Body>
              <DataTable value={listDelayedClass.delayedClass} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                rowsPerPageOptions={[5, 10, 25, 50]} header={renderHeader()} filters={filters} 
                globalFilterFields={['namaKelas', 'daftarSesi.sesi', 'daftarSesi.jadwal']}
              >
                <Column sortable field='index' header='No'></Column>
                <Column sortable field='namaKelas' header='Nama Kelas'></Column>
                <Column sortable field='daftarSesi.sesi' header='Sesi'></Column>
                <Column sortable field='daftarSesi.jadwal' header='Jadwal Sesi' body={(e) => scheduleTemplate(e.daftarSesi.jadwal)}></Column>
                <Column body={actionBodyTemplate}></Column>
              </DataTable>
            </Card.Body>
          </Card>
           : 
          ""
        }

        <h5 style={{marginTop: '20px'}}>Kelas Hari Ini</h5>
        <div className={styles.currentClass}>
          {listCard(listKelas)}
          {actionButtonLoadMore(listKelas)}
        </div>
    </Page>
  )
}

export default Dashboard
