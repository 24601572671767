import Page from "../../Layouts/Page/Page";
import React, {useState, useEffect, useMemo, useRef} from "react";
import {Card} from 'primereact/card';
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// import {Calendar} from "primereact/calendar";
import {Toast} from "primereact/toast";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {BASE_API_URL} from "../../helpers";
import axios from "axios";
// import {FloatLabel} from "primereact/floatlabel";

const groupDataBySchedule = (data) => {
    return data.reduce((acc, item) => {
        (acc[item.hari] = acc[item.hari] || []).push(item);
        return acc;
    }, {});
};

const setScheduleStorage = async () => {
    const id = localStorage.getItem("teacherID");
    const options = {
        method: 'get',
        url: `${BASE_API_URL}/teachers/${id}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios.request(options)
        .then(({data}) => {
            localStorage.setItem('availableSchedule', data.availableSchedule);
            return data.availableSchedule;
        })
}

const createAvailableSchedule = async () => {
    const id = localStorage.getItem("teacherID");
    const options = {
        method: "post",
        url: `${BASE_API_URL}/create-available-schedule`,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            "guru": `${id}`,
        },
    };

    return axios.request(options)
        .then(({data}) => {
            localStorage.setItem('availableSchedule', data._id);
            return data._id;
        })
}

const getAvailableSchedule = async () => {
    const id = localStorage.getItem("teacherID");

    const options = {
        method: "post",
        url: `${BASE_API_URL}/available-schedule`,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            "guru": `${id}`,
        },
    };

    return axios.request(options)
        .then(({data}) => {
            return data
        })
}

const addSchedule = async (newData) => {
    const id = localStorage.getItem("availableSchedule");

    const options = {
        method: "post",
        url: `${BASE_API_URL}/add-available-schedule/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            "hari": `${newData.hari}`,
            "jamAwal": `${newData.jamAwal}`,
            "jamAkhir": `${newData.jamAkhir}`
        },
    };

    return axios.request(options)
        .then(({data}) => {
            return data
        })
}

const delSchedule = async (timeId) => {
    const scheduleId = localStorage.getItem("availableSchedule");

    const options = {
        method: "delete",
        url: `${BASE_API_URL}/remove-time-available-schedule`,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            "scheduleId": `${scheduleId}`,
            "timeId": `${timeId}`
        },
    };

    return axios.request(options)
        .then(({data}) => {
            return data
        })
}

const delDaysSchedule = async (hari) => {
    const scheduleId = localStorage.getItem("availableSchedule");

    const options = {
        method: "delete",
        url: `${BASE_API_URL}/remove-day-available-schedule`,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            "scheduleId": `${scheduleId}`,
            "hari": `${hari}`
        },
    };

    return axios.request(options)
        .then(({data}) => {
            return data
        })
}

const editSchedule = async (newData) => {
    const scheduleId = localStorage.getItem("availableSchedule");

    const options = {
        method: "post",
        url: `${BASE_API_URL}/edit-time-available-schedule`,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            "scheduleId": `${scheduleId}`,
            "timeId": `${newData._id}`,
            "hari": `${newData.hari}`,
            "jamAwal": `${newData.jamAwal}`,
            "jamAkhir": `${newData.jamAkhir}`
        },
    };

    return axios.request(options)
        .then(({data}) => {
            return data
        })
}

const editPreference = async (newData) => {
    const scheduleId = localStorage.getItem("availableSchedule");
    const options = {
        method: "post",
        url: `${BASE_API_URL}/update-available-schedule/${scheduleId}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            "usia": newData.usia,
            "jenisKelas": newData.jenisKelas,
            "subjek": newData.subjek
        },
    };

    return axios.request(options)
      .then(({data}) => {
          return data
      })
}

const Preference = () => {
    const [jenisKelas, setJenisKelas] = useState([]);
    const [rentangUsia, setRentangUsia] = useState([]);
    const [subject, setSubject] = useState([]);
    const [disable, setDisable] = useState(true);
    const [preference, setPreference] = useState({
        usia:[],
        jenisKelas:[]
    })
    const toast = useRef(null);
    const onJenisKelasChange = (e) => {
        let _jenisKelas = [...jenisKelas];

        if (e.checked) _jenisKelas.push(e.value); else _jenisKelas.splice(_jenisKelas.indexOf(e.value), 1);

        setJenisKelas(_jenisKelas);
    }
    const onRentangUsiaChange = (e) => {
        let _rentangUsia = [...rentangUsia];

        if (e.checked) _rentangUsia.push(e.value); else _rentangUsia.splice(_rentangUsia.indexOf(e.value), 1);

        setRentangUsia(_rentangUsia);
    }

    const onSubjectChange = (e) => {
        let _subject = [...subject];

        if (e.checked) _subject.push(e.value); else _subject.splice(_subject.indexOf(e.value), 1);

        setSubject(_subject);
    }

    const cancelPreference = ()=>{
        setRentangUsia(preference.usia)
        setJenisKelas(preference.jenisKelas)
        setSubject(preference.subject)
    }

    const updatePreference = ()=> {
        const newData = {
            jenisKelas: jenisKelas,
            usia: rentangUsia,
            subjek: subject
        }

        editPreference(newData).then(() => {
            toast.current.show({
                severity: 'success',
                summary: 'Successful',
                detail: 'Data Berhasil Diperbarui',
                life: 3000
            });
            setRefresh(prev=> !prev)
        }).catch((error) => {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: `${error}`,
                life: 3000
            })
        })
    }

    const [nodes, setNodes] = useState([]);
    const [startTime, setStartTime] = useState({});
    const [endTime, setEndTime] = useState({});
    const [selectedDay, setSelectedDay] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const days = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'];

    const [expandedRows, setExpandedRows] = useState([]);

    useEffect(() => {
        let availableSchedule = localStorage.getItem('availableSchedule');

        if (!availableSchedule || availableSchedule === "undefined") {
            setScheduleStorage().then((scheduleId) => {
                if (scheduleId)
                    availableSchedule = scheduleId
                else
                    createAvailableSchedule().then((scheduleId) => availableSchedule = scheduleId).catch((error) => {
                        console.log(error)
                    })
            }).catch((error) => {
                console.log(error)
            })
        }
    }, []);

    useEffect(() => {
        getAvailableSchedule().then((data) => {
            setNodes(data.jadwal.sort((a, b) => {
                return days.findIndex(day => day === a.hari) - days.findIndex(day => day === b.hari);
            }))
            setPreference({jenisKelas: data.jenisKelas, usia: data.usia, subject: data.subjek})
            setSubject(data.subjek)
            setRentangUsia(data.usia)
            setJenisKelas(data.jenisKelas)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh]);

    useEffect(() => {
        if (JSON.stringify(jenisKelas.sort())===JSON.stringify(preference.jenisKelas.sort())
          && JSON.stringify(rentangUsia.sort())===JSON.stringify(preference.usia.sort())
          && JSON.stringify(subject)===JSON.stringify(preference.subject)
        )
            setDisable(true)
        else
            setDisable(false)
    }, [jenisKelas, rentangUsia,subject]);

    const headerTemplate = (data) => {
        const dayDelete = () => {
            const accept = () => {
                delDaysSchedule(data.hari).then(() => {
                    toast.current.show({
                        severity: 'info',
                        summary: 'Confirmed',
                        detail: 'Jadwal Berhasil Dihapus',
                        life: 3000
                    });
                    setRefresh(prev => !prev)

                }).catch((error) => {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: `${error}`,
                        life: 3000
                    })
                })
            }

            confirmDialog({
                message: `Apakah anda yakin ingin menghapus semua jadwal hari ${data.hari}?`,
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept
            });
        }

        return <div className="d-inline-flex w-11 justify-content-between align-items-center">
            <span className="ml-2 font-bold">{data.hari}</span>
            <Button type="button" icon="pi pi-trash" onClick={dayDelete} severity="danger" className="ml-2" rounded/>
        </div>

    };

    const handleAddDay = () => {

        if (selectedDay && startTime && endTime) {
            const jamAwal = new Date()
            jamAwal.setHours(parseInt(startTime.hours),parseInt(startTime.minutes))
            const jamAkhir = new Date()
            jamAkhir.setHours(parseInt(endTime.hours),parseInt(endTime.minutes))

            const newItem = {
                hari: selectedDay,
                jamAwal,
                jamAkhir
            }

            addSchedule(newItem).then((res) => {
                setRefresh(prev => !prev);
                setExpandedRows([res.data[0]])
                setSelectedDay(null);
                setEndTime({});
                setStartTime({});
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Jadwal Berhasil Ditambah',
                    life: 3000
                });
            }).catch((error) => {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: `${error}`,
                    life: 3000
                });
            })
        }
    };


    const groupedData = useMemo(() => groupDataBySchedule(nodes), [nodes]);
    const hours = Array.from({ length: 24 }, (_, i) => (i).toString().padStart(2, '0'));
    const minutes = ["00","30"]
    const groupTableTemplate = (data) => {
        const handleDelete = (rowData) => {
            const accept = () => {
                delSchedule(rowData._id).then(() => {
                    toast.current.show({
                        severity: 'info',
                        summary: 'Confirmed',
                        detail: 'Jadwal Berhasil Dihapus',
                        life: 3000
                    });
                    setRefresh(prev => !prev)

                }).catch((error) => {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: `${error}`,
                        life: 3000
                    })
                })
            }

            confirmDialog({
                message: 'Apakah anda yakin ingin menghapusnya?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept
            });
        };
        const actionTemplate = (rowData) => {
            return <Button type="button" icon="pi pi-trash" onClick={() => handleDelete(rowData)} severity="danger"
                           rounded></Button>
        }
        const timePickerEditor = (options, label) => {
            // const hash = Number(new Date()).toString(36)

            // if (options.value) {
            //     options.value = new Date(options.value);
            // }
            const dt = new Date(options.value)
            if (!isNaN(dt.getTime()))
                options.value = {
                    hours: dt.getHours().toString().padStart(2,"0"),
                    minutes: dt.getMinutes().toString().padStart(2,"0")
                }

            return (
                // <FloatLabel>
                //     <Calendar
                //         value={options.value}
                //         onChange={(e) => options.editorCallback(e.target.value)}
                //         showTime
                //         timeOnly
                //         id={hash}
                //         placeholder={label}
                //         onKeyDown={(e) => e.stopPropagation()}
                //     />
                    // <label htmlFor={hash}>{label}</label>
                // </FloatLabel>
              <>
                <Dropdown options={hours} className="mr-2" value={options.value.hours} onChange={(e) => options.editorCallback({...options.value, hours: e.target.value})} />
                  :
                <Dropdown options={minutes} className="ml-2" value={options.value.minutes} onChange={(e) => options.editorCallback({...options.value, minutes: e.target.value})} />
              </>
            );
        };

        const datePickerEditor = (options) => {
            return (
                <Dropdown
                    value={options.value}
                    options={days}
                    onChange={(e) => options.editorCallback(e.value)}
                    placeholder="Pilih hari"
                />
            );
        }
        const onRowEditComplete = (e) => {
            let {newData} = e;
            let jamAwal = newData.jamAwal.hours
              ? new Date().setHours(parseInt(newData.jamAwal.hours), parseInt(newData.jamAwal.minutes))
              : new Date(newData.jamAwal);

            let jamAkhir = newData.jamAkhir.hours
              ? new Date().setHours(parseInt(newData.jamAkhir.hours), parseInt(newData.jamAkhir.minutes))
              : new Date(newData.jamAkhir);

            // Pastikan untuk mengkonversi kembali dari milidetik ke objek Date jika menggunakan setHours()
            jamAwal = new Date(jamAwal);
            jamAkhir = new Date(jamAkhir);

            newData = {
                ...newData,
                jamAwal,
                jamAkhir
            }

            if (newData.jamAwal && newData.jamAkhir) {
                editSchedule(newData).then((res) => {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Jadwal Berhasil Diperbarui',
                        life: 3000
                    });
                    setRefresh(prev => !prev);
                    setExpandedRows([res.updatedEntry])

                }).catch((error) => {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: `${error}`,
                        life: 3000
                    })
                })
            }
        };

        const timeBody = (rowData, field) => {
            const time = new Date(rowData[field])
            return time.toLocaleTimeString('it-IT', {hour: "2-digit", minute: "2-digit"})
        }

        const items = groupedData[data.hari] || [];
        return (
            <td >
                <DataTable value={items} editMode="row" onRowEditComplete={onRowEditComplete} style={{width: '90%'}}>
                    <Column style={{width: '5%'}}></Column>
                    <Column field="hari" header="Hari" style={{width: '15%'}}
                            editor={(options) => datePickerEditor(options)}
                    ></Column>
                    <Column editor={(options) => timePickerEditor(options, "Jam Mulai")} field="jamAwal"
                            header="Jam Mulai" body={(rowData) => timeBody(rowData, 'jamAwal')}
                            style={{width: '35%'}}
                    ></Column>
                    <Column editor={(options) => timePickerEditor(options, "Jam Selesai")} field="jamAkhir"
                            header="Jam Selesai" body={(rowData) => timeBody(rowData, 'jamAkhir')}
                            style={{width: '35%'}}></Column>
                    <Column rowEditor={true}
                            style={{width: '10%'}}
                            bodyStyle={{textAlign: 'right'}}
                    ></Column>
                    <Column body={actionTemplate} style={{width: '30%'}}/>
                </DataTable>
            </td>
        );
    };

    return (<Page>
        <Toast ref={toast}/>
        <ConfirmDialog/>
        <Card>
            Silahkan isi jadwal kosong anda dan lengkapi preferensi kelas yang anda inginkan !
        </Card>
        <Card title="Preferensi Kelas" className="mt-4">

            {/* Tipe Kelas */}
            <div className="flex items-center mb-4">
                <label className="w-32 text-right text-lg mr-4 text-gray-700">Tipe Kelas</label>
                <div className="flex flex-wrap justify-content-center gap-3">
                    <div className="flex align-items-center">
                        <Checkbox inputId="private" name="jenisKelas" value="private" onChange={onJenisKelasChange}
                                  checked={jenisKelas.includes("private")}/>
                        <label htmlFor="private" className="ml-2">Private</label>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="small" name="jenisKelas" value="small" onChange={onJenisKelasChange}
                                  checked={jenisKelas.includes("small")}/>
                        <label htmlFor="small" className="ml-2">Small</label>
                    </div>
                </div>
            </div>

            {/* Rentang Usia */}
            <div className="flex ir mb-4">
                <label className="w-32 text-right text-lg mr-4 text-gray-700">Rentang Usia</label>
                <div className="flex flex-wrap justify-content-center gap-3">
                    <div className="flex align-items-center">
                        <Checkbox inputId="5 - 7" name="rentangUsia" value="5 - 7" onChange={onRentangUsiaChange}
                                  checked={rentangUsia.includes("5 - 7")}/>
                        <label htmlFor="5 - 7" className="ml-2">5 - 7</label>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="8 - 10" name="rentangUsia" value="8 - 10" onChange={onRentangUsiaChange}
                                  checked={rentangUsia.includes("8 - 10")}/>
                        <label htmlFor="8 - 10" className="ml-2">8 - 10</label>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="11 - 13" name="rentangUsia" value="11 - 13" onChange={onRentangUsiaChange}
                                  checked={rentangUsia.includes("11 - 13")}/>
                        <label htmlFor="11 - 13" className="ml-2">11 - 13</label>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="Berumur" name="rentangUsia" value="Berumur" onChange={onRentangUsiaChange}
                                  checked={rentangUsia.includes("Berumur")}/>
                        <label htmlFor="Berumur" className="ml-2">&gt; 13</label>
                    </div>
                </div>
            </div>

            {/* Subject */}
            <div className="flex items-center mb-4">
                <label className="w-32 text-right text-lg mr-4 text-gray-700">Subject</label>
                <div className="flex flex-wrap justify-content-center gap-3">
                    <div className="flex align-items-center">
                        <Checkbox inputId="Adab" name="subject" value="Adab" onChange={onSubjectChange}
                                  checked={subject.includes("Adab")}/>
                        <label htmlFor="Adab" className="ml-2">Adab</label>
                        <Button id="Adab" severity="help" outlined label="?" tooltip="Adab" tooltipOptions={{position: 'top'}} className="ms-1 p-1 py-0 border-circle text-xs"/>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="AFQ" name="subject" value="AFQ" onChange={onSubjectChange}
                                  checked={subject.includes("AFQ")}/>
                        <label htmlFor="AFQ" className="ml-2">AFQ</label>
                        <Button id="Arabic for Quran" severity="help" outlined label="?" tooltip="Arabic for Quran" tooltipOptions={{position: 'top'}} className="ms-1 p-1 py-0 border-circle text-xs"/>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="IC Atas" name="subject" value="IC Atas" onChange={onSubjectChange}
                                  checked={subject.includes("IC Atas")}/>
                        <label htmlFor="IC Atas" className="ml-2">IC Atas</label>
                        <Button id="Islamic Coding Atas" severity="help" outlined label="?" tooltip="Islamic Coding Atas" tooltipOptions={{position: 'top'}} className="ms-1 p-1 py-0 border-circle text-xs"/>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="IC Bawah" name="subject" value="IC Bawah" onChange={onSubjectChange}
                                  checked={subject.includes("IC Bawah")}/>
                        <label htmlFor="IC Bawah" className="ml-2">IC Bawah</label>
                        <Button id="Islamic Coding Bawah" severity="help" outlined label="?" tooltip="Islamic Coding Bawah" tooltipOptions={{position: 'top'}} className="ms-1 p-1 py-0 border-circle text-xs"/>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="ICWS" name="subject" value="ICWS" onChange={onSubjectChange}
                                  checked={subject.includes("ICWS")}/>
                        <label htmlFor="ICWS" className="ml-2">ICWS</label>
                        <Button id="Islamic Creative Writing & Storytelling" severity="help" outlined label="?" tooltip="Islamic Creative Writing & Storytelling" tooltipOptions={{position: 'top'}} className="ms-1 p-1 py-0 border-circle text-xs"/>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="IE" name="subject" value="IE" onChange={onSubjectChange}
                                  checked={subject.includes("IE")}/>
                        <label htmlFor="IE" className="ml-2">IE</label>
                        <Button id="Islamic English" severity="help" outlined label="?" tooltip="Islamic English" tooltipOptions={{position: 'top'}} className="ms-1 p-1 py-0 border-circle text-xs"/>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="IL & SoIP" name="subject" value="IL & SoIP" onChange={onSubjectChange}
                                  checked={subject.includes("IL & SoIP")}/>
                        <label htmlFor="IL & SoIP" className="ml-2">IL & SoIP</label>
                        <Button id="Islamic Leadership & Story of Islamic Prophet" severity="help" outlined label="?" tooltip="Islamic Leadership & Story of Islamic Prophet" tooltipOptions={{position: 'top'}} className="ms-1 p-1 py-0 border-circle text-xs"/>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="NI" name="subject" value="NI" onChange={onSubjectChange}
                                  checked={subject.includes("NI")}/>
                        <label htmlFor="NI" className="ml-2">NI</label>
                        <Button id="Numerasi Islam" severity="help" outlined label="?" tooltip="Numerasi Islam" tooltipOptions={{position: 'top'}} className="ms-1 p-1 py-0 border-circle text-xs"/>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="QT" name="subject" value="QT" onChange={onSubjectChange}
                                  checked={subject.includes("QT")}/>
                        <label htmlFor="QT" className="ml-2">QT</label>
                        <Button id="Quran Tajweed" severity="help" outlined label="?" tooltip="Quran Tajweed" tooltipOptions={{position: 'top'}} className="ms-1 p-1 py-0 border-circle text-xs"/>
                    </div>
                </div>
            </div>
            <Button label="Update" onClick={updatePreference} severity="success" className="rounded-3 mr-3" disabled={disable}/>
            <Button label="Cancel" onClick={cancelPreference} severity="warning" className="rounded-3" disabled={disable}/>
        </Card>
        <Card title="Preferensi Jadwal" className="mt-4">

            <div className="flex-col mb-4 md:flex">
                {/*<FloatLabel className="w-full mr-4 mt-4">*/}
                <div className="flex-auto mr-4 w-4 mb-4">
                    <label className="font-bold">Pilih Hari</label>
                    <Dropdown
                        value={selectedDay}
                        onChange={(e) => {
                            setSelectedDay(e.value)
                        }}
                        options={days}
                        id="day-dropdown"
                        placeholder="Pilih hari"
                        className="w-full mt-2"
                    />
                </div>
                {/*    <label htmlFor="day-dropdown">Pilih hari</label>*/}
                {/*</FloatLabel>*/}

                {/*<FloatLabel className="w-6 mr-4 mt-4">*/}
                {/*    <Calendar value={startTime} id="Jam Mulai" onChange={(e) => {*/}
                {/*        if (e.value instanceof Date && !isNaN(e.value.getTime()))*/}
                {/*            setStartTime(e.value)*/}
                {/*    }} className="w-full mr-4 mt-4" placeholder="Jam Mulai" timeOnly/>*/}
                {/*    <label htmlFor="Jam Mulai">Jam Mulai</label>*/}
                {/*</FloatLabel>*/}

                <div className="flex-auto mb-4">
                    <label className="font-bold">Jam Awal</label>
                    <div className="flex align-items-center mr-4 mt-2">
                        <Dropdown options={hours} className="mr-2"
                          value={startTime.hours}
                          onChange={(e) => setStartTime(prev=> ({...prev, hours: e.target.value}))}
                        />
                        :
                        <Dropdown options={minutes} className="ml-2"
                                  value={startTime.minutes}
                                  onChange={(e) => setStartTime(prev=> ({...prev, minutes: e.target.value}))}
                        />
                    </div>
                </div>

                {/*<FloatLabel className="w-6 mr-4 mt-4">*/}
                {/*    <Calendar value={endTime} id="Jam Selesai" onChange={(e) => {*/}
                {/*        if (e.value instanceof Date && !isNaN(e.value.getTime()))*/}
                {/*            setEndTime(e.value)*/}
                {/*    }} className="w-full mr-4 mt-4" placeholder="Jam Selesai" timeOnly/>*/}
                {/*    <label htmlFor="Jam Selesai">Jam Selesai</label>*/}
                {/*</FloatLabel>*/}
                <div className="flex-auto mb-4">
                    <label className="font-bold">Jam Akhir</label>
                    <div className="flex align-items-center mr-4 mt-2">
                        <Dropdown options={hours} className="mr-2"
                                  value={endTime.hours}
                                  onChange={(e) => setEndTime(prev=> ({...prev, hours: e.target.value}))}
                        />
                        :
                        <Dropdown options={minutes} className="ml-2"
                                  value={endTime.minutes}
                                  onChange={(e) => setEndTime(prev=> ({...prev, minutes: e.target.value}))}
                        />
                    </div>
                </div>

                <div className="mt-4 mb-4 flex justify-center align-items-end md:justify-start">
                    <Button
                        label="Tambah"
                        className="rounded-3"
                        onClick={handleAddDay}
                    />
                </div>
            </div>

            <DataTable
                value={nodes}
                rowGroupMode="subheader"
                groupRowsBy="hari"
                expandableRowGroups
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowGroupHeaderTemplate={headerTemplate}
                rowGroupFooterTemplate={groupTableTemplate}
                tableStyle={{minWidth: '50rem'}}
            />
        </Card>
    </Page>)
};

export default Preference;
