import React, { useState } from 'react'
import styles from './SignUp.module.css'
import Page from '../../Layouts/Page/Page'
import { Card, Form } from 'react-bootstrap'
import { IconEdufic } from '../../assets'
import { BASE_API_URL } from '../../helpers'
import { listFinanceAccount } from '../../helpers/listBank'
import { Dialog } from 'primereact/dialog'
import { data } from 'jquery'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'

const SignUp = () => {
  const navigate = useNavigate()
  const [ showPassword, setShowPassword ] = useState(false)
  const [ confirmShowPassword, setConfirmShowPassword ] = useState(false)
  const [ dialogError, setDialogError ] = useState(false)
  const [ dialogErrorMessage, setDialogErrorMessage] = useState(null)
  const [ dataTeacher, setDataTeacher ] = useState({
    nama: '',
    panggilan: '',
    nomor: 62,
    tempatLahir: '',
    tanggalLahir: '',
    domisili: '',
    pendidikan: '',
    namaBank: '',
    rekening: '',
    email: '',
    password: '',
    confirmPassword: ''
  })
  
  const changeVisibilityPass = (type) => {
    if (type == 'password') setShowPassword(!showPassword)
    else setConfirmShowPassword(!confirmShowPassword)
  }

  const handleUserChange = (event) => {
    const { name, value } = event.target
    setDataTeacher((prevState) => ({
        ...prevState,
        [name]: value
    }))
    console.log(dataTeacher)
  }

  const footerDialogError = (
    <div className='text-center mt-0'>
      <Button label="Ok" onClick={() => setDialogError(false)} autoFocus />
    </div>
  )

  const teacherRegister = async (event) => {
    event.preventDefault()
    
    const dataRegister = JSON.stringify({
        nama: dataTeacher.nama,
        panggilan: dataTeacher.panggilan,
        nomor: dataTeacher.nomor,
        tempatLahir: dataTeacher.tempatLahir,
        tanggalLahir: dataTeacher.tanggalLahir,
        domisili: dataTeacher.domisili,
        pendidikan: dataTeacher.pendidikan,
        namaBank: dataTeacher.namaBank,
        rekening: dataTeacher.rekening,
        email: dataTeacher.email,
        password: dataTeacher.password,
        confirmPassword: dataTeacher.confirmPassword
    })

    if (dataTeacher.password !== dataTeacher.confirmPassword) {
        setDialogErrorMessage('Konfirmasi Password tidak sama')
        setDialogError(true)
    } else {
        const optionRegister = {
            method: 'post',
            url: `${BASE_API_URL}/teachers`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: dataRegister
        }
    
        console.log(dataRegister)
        
        await axios.request(optionRegister)
          .then((response) => {
            if(response.status == 201) {
                navigate('/')
            }
          })
          .catch((error) => {
            setDialogErrorMessage(error.response.data.message)
            setDialogError(true)
          })
    }
  }

  return (
    <Page>
        <Card className={styles.mycard}>
            <Card.Body >
                <Card.Title className={styles.titleForm}>
                    <img 
                        src={IconEdufic} 
                        alt="Icon-Edufic" 
                        height='50'
                    />
                    
                    <h2>Teacher Portal</h2>
                </Card.Title>
                <Form id='form-submit' onSubmit={teacherRegister}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Nama Lengkap</Form.Label>
                        <Form.Control 
                            type='text' 
                            placeholder="Masukkan Nama Lengkap"
                            name='nama'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicNickName">
                        <Form.Label>Nama Panggilan</Form.Label>
                        <Form.Control 
                            type='text' 
                            placeholder="Masukkan Nama Panggilan"
                            name='panggilan'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                        <Form.Label>No Telepon</Form.Label>
                        <Form.Control 
                            type='number' 
                            placeholder="62 ...."
                            name='nomor'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEducation">
                        <Form.Label>Pendidikan</Form.Label>
                        <Form.Control 
                            type='text' 
                            placeholder="ex: S1 Hukum UI"
                            name='pendidikan'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Tempat, tanggal lahir</Form.Label>
                        <div className={styles.inlineForm}>
                            <Form.Control 
                                type='text' 
                                placeholder="Kota/Kab kelahiran"
                                name='tempatLahir'
                                onChange={handleUserChange}
                                required
                            />
                            <Form.Control 
                                type='date' 
                                placeholder="dd/mm/yyyy"
                                name='tanggalLahir'
                                onChange={handleUserChange}
                                required
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicAddress">
                        <Form.Label>Kota/Kabupaten Domisili</Form.Label>
                        <Form.Control 
                            type='text' 
                            placeholder="Masukkan nama kabupaten / kota saja"
                            name='domisili'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicBankName">
                        <Form.Label>Nama Bank</Form.Label>
                        <Form.Control 
                            type='text'
                            placeholder="Masukkan nama bank"
                            name='namaBank'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicFinanceAccount">
                        <Form.Label>No. Rekening</Form.Label>
                        <Form.Control 
                            type='number'
                            placeholder="Masukkan no rekening"
                            name='rekening'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Alamat Email</Form.Label>
                        <Form.Control 
                            type='email' 
                            placeholder="Masukkan Email Aktif"
                            name='email'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                            type= {showPassword ? 'text' : 'password'} 
                            placeholder="Masukkan Password Anda"
                            name='password'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPass">
                        <Form.Check 
                            type="checkbox" 
                            label="Tampilkan kata sandi"  
                            onChange={() => changeVisibilityPass('password')}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                        <Form.Label>Konfirmasi Password</Form.Label>
                        <Form.Control 
                            type= {confirmShowPassword ? 'text' : 'password'} 
                            placeholder="Masukkan Password Anda"
                            name='confirmPassword'
                            onChange={handleUserChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicConfirmPass">
                        <Form.Check 
                            type="checkbox" 
                            label="Tampilkan konfirmasi kata sandi"  
                            onChange={() => changeVisibilityPass('confirmation-password')}
                        />
                    </Form.Group>
                    <div className='text-center'>
                        <button type='submit' className={styles.mybutton} onClick={teacherRegister}>
                            Register
                        </button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
        <Dialog visible={dialogError} header='Gagal Mendaftar' footer={footerDialogError} 
          onHide={() => setDialogError(false)} style={{width:'30rem'}}>
            <div className='text-center mb-0'>
                <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '6rem', color: 'red' }} />
                <p style={{ fontSize: '18px'}}>{dialogErrorMessage}</p>
            </div>
        </Dialog>
    </Page>
  )
}

export default SignUp
