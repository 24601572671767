import React, { useState } from 'react'
import styles from './NavBar.module.css'
import { Logo } from '../../assets'
import { Button } from 'primereact/button'
import { Sidebar } from 'primereact/sidebar'
import SideBarMobile from '../SideBar/SideBarMobile'


const NavBarLogin = () => {
  const currentName = localStorage.getItem('teacherNickName')
  const [ sideBarMobile, setSideBarMobile ] = useState(false)

  const headerSideBarTemplate = () => {
    return (
      <a href="https://edufic.id">
        <img src={Logo} alt="Edufic" height="50px" />
      </a>
    )
  }
  return (
    <div className={styles.navbar}>
      <h4>Selamat datang, {currentName !== null ? currentName : 'Teachers' }! </h4>
      <Button icon="pi pi-bars" className={styles.myBars} onClick={() => setSideBarMobile(true)}/>
      
      <Sidebar visible={sideBarMobile} position='right' onHide={() => setSideBarMobile(false)}
        header={headerSideBarTemplate}>
        <SideBarMobile/>
      </Sidebar>
    </div>
  )
}

export default NavBarLogin