import React, { useState } from 'react'
import { Logo } from '../../assets'
import styles from './SideBar.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading/Loading'

const SideBarMobile = () => {
  const location = useLocation()
  const currentLocation = location.pathname.split('/')[1]
  const navigate = useNavigate()
  const [ loading, setLoading ] = useState(false)

  const userLogout = () => {
    setLoading(true)
    setTimeout(() => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('email')
        navigate('/')
    }, 500)
    setLoading(false)
  }

  return (
    <div>
      <Loading visible={loading}/>
      <div className={styles.sidebarcontent}>
        <div className={styles.sidebarmenu}>
            <a href="/dashboard">
            <div className={currentLocation == 'dashboard' ? styles.activemenu : styles.inactivemenu}>
                Dashboard
            </div>
            </a>
            <a href="/class-management">
            <div className={currentLocation == 'class-management' ? styles.activemenu : styles.inactivemenu}>
                Manajemen Kelas
            </div>
            </a>
        </div>
        <div className={styles.logoutContainer}>
            <button className={styles.logoutbutton} onClick={userLogout}>Logout</button>
        </div>
      </div>
    </div>
  )
}

export default SideBarMobile
