import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { putData } from '../../helpers/api'

const RemoveClassModal = ({visible, visibleHandler, refreshAction, setLoading, detailAttendance, toast}) => {
  const accessToken = localStorage.getItem('accessToken');

  const removeStudent = (data, form) => {
    setLoading(true);
    data.absensiId = detailAttendance.id;
    // console.log(data);
    
    putData('class-registration/remove-student-class', data, accessToken)
    .then((response) => {
        toast.current.show({
            severity:'success', 
            summary: response.data.title, 
            detail: response.data.message, 
            life: 3000,
        });
        form.restart();
        refreshAction();
        setLoading(false);
        visibleHandler();
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: "Error",
            detail: error.response.data.message
        });
        setLoading(false);
    });
  }

  const removeStudentValidate = (data) => {
    let errors = {};

    if (!data.noteClass) errors.noteClass = 'Reason is required for analyse';
  }
  
  return (
    <Dialog
        header="Pindahkan Siswa"
        visible={visible}
        onHide={() => visibleHandler()}
        style={{ width: '40rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
        className='p-fluid'
    >
        <Form
            onSubmit={removeStudent}
            validate={removeStudentValidate}
            initialValues={{noteClass: ""}}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name='noteClass'
                        render={({input, meta}) => (
                            <div className='mb-3'>
                                <label className='font-bold block mb-2'>Alasan Perpindahan</label>
                                <InputTextarea
                                    id='noteClass'
                                    name='noteClass'
                                    {...input}
                                    placeholder='Input the reason ...'
                                    rows={2}
                                />
                            </div>
                        )}
                    />
                    <div className='flex justify-content-end gap-2'>
                        <Button type='button' label='Batal' severity='info' className='w-max' outlined onClick={() => visibleHandler()}></Button>
                        <Button type='submit' label='Pindah' severity='danger' className='w-max'></Button>
                    </div>      
                </form>
            )}
        />
    </Dialog> 
  )
}

export default RemoveClassModal
