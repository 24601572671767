import React from 'react'
import styles from './Page.module.css'
import NavbarUnLogin from '../Navbar/NavbarUnLogin'
import { Container } from 'react-bootstrap'
import SideBar from '../SideBar/SideBar'
import MainContent from '../MainContent/MainContent'

const Page = (props) => {
  const isLoggin = localStorage.getItem('accessToken')
  return (
    !isLoggin ? 
      <div className={styles.wrapper}>
        <NavbarUnLogin/>
        <Container>{props.children}</Container>
      </div>
      :
      <div className={styles.wrapper}>
        <div className={styles.maincontent}>
          <SideBar/>
          <MainContent>{props.children}</MainContent>
        </div>
      </div>
  )
}

export default Page
