import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { Logo } from '../../assets'
import { useLocation } from 'react-router-dom'
import styles from './NavBar.module.css'

const NavbarUnLogin = () => {
  const location = useLocation()
  const currentLocation = location.pathname.split('/')[1]

  return (
    <Navbar>
        <Container>
            <Navbar.Brand href='https://edufic.id/'>
                <img 
                    src={Logo} 
                    alt="Edufic"
                    height="40"
                />
            </Navbar.Brand>
            <Nav>
                <a href="/" className={currentLocation == "" ? styles.activeButton : styles.passiveButton}>Beranda</a>
                <a href="/recruitment" className={currentLocation == "recruitment" ? styles.activeButton : styles.passiveButton}>Daftar</a>
                <a href="#" className={currentLocation == "/help-service" ? styles.activeButton : styles.passiveButton}>Bantuan</a>
                {/* <Nav.Link href="/" className={currentLocation == "" ? styles.activeButton : 'ms-3'}>Home</Nav.Link>
                <Nav.Link href="/recruitment" className={currentLocation == "recruitment" ? styles.activeButton : 'ms-3'}>Recruitment</Nav.Link> */}
                {/* <Nav.Link href="#" className='ms-3'>Help Service</Nav.Link> */}
            </Nav>
        </Container>
    </Navbar>
  )
}

export default NavbarUnLogin