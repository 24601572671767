import React, {useState, useEffect, useRef} from 'react'
import { useParams } from 'react-router-dom'
import styles from './DetailClass.module.css'
import Page from '../../Layouts/Page/Page'
import { Card } from 'react-bootstrap'
import { Chart } from 'primereact/chart'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import axios from 'axios'
import { BASE_API_URL, formattedDate, formattedDate2, formattedDateWithDay, getSeverity, stringToDate } from '../../helpers'
import Loading from '../../components/Loading/Loading'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { InputTextarea } from 'primereact/inputtextarea'
import { IconFamily, SuccessPTC } from '../../assets'
import { SelectButton } from 'primereact/selectbutton'
import { Tag } from 'primereact/tag'
import UnverifiedToken from '../../components/UnverifiedToken'
import { Toast } from 'primereact/toast'
import RemoveClassModal from './removeClassModal'

const DetailClass = () => {
  const { id } = useParams()
  const clipBoardLink = useRef(null)
  const clipBoardAlias = useRef(null)
  const toast = useRef(null);
  const dtPresence = useRef(null)
  const dtPTC = useRef(null)
  const dtRoomHistory = useRef(null)
  const [ copySuccess, setCopySuccess ] = useState('');
  const [ copyAliasSuccess, setCopyAliasSuccess ] = useState('');
  const [ chartSessionOption, setChartSessionOption ] = useState({})
  const [ chartAttendanceOption, setChartAttendanceOption ] = useState({})
  const [ chartSessionData, setChartSessionData ] = useState({});
  const [ chartAttendanceData, setChartAttendanceData ] = useState({});
  const [ rowDialog, setRowDialog ] = useState(false);
  const [ scheduleUpdateDialog, setScheduleUpdateDialog ] = useState(false);
  const [ removeStudentDialog, setRemoveStudentDialog ] = useState(false);
  const [ detailAttendance, setDetailAttendance ] = useState({});
  const [ requestClassRoom, setRequestClassRoom ] = useState(false)
  const [classAlias, setClassAlias] = useState('');
  const [classAliasInput, setClassAliasInput] = useState('');
  const [ sessionUpdateDialog, setSessionUpdateDialog ] = useState(false)
  const [ ptcSessionDialog, setPtcSessionDialog ] = useState(false)
  const [ ptcUpdateDialog, setPtcUpdateDialog ] = useState(false)
  const [ continuation, setContinuation ] = useState(false)
  const [ endClassDialog, setEndClassDialog ] = useState(false)
  const [ detailSessionPTC, setDetailSessionPTC ] = useState({
    idAttendance: null,
    index: null,
    jadwal: null,
    studentName: null,
    kehadiran: null,
    review: '',
  })
  const [ detailContinuation, setDetailContinuation ] = useState({
    idAttendance: null,
    namaSiswa: null,
    kelanjutanSiswa: null,
    alasan: null
  })
  const [ selectedCell, setSelectedCell ] = useState(null)
  const [ selectedPtcCell, setSelectedPtcCell ] = useState(null)
  const [ startPTC, setStartPTC ] = useState('none')
  const optionsStatus = ['Lanjut', 'Berhenti']
  const [ selectedStudent, setSelectedStudent ] = useState({
    id: null,
    name: null
  })
  const [isEditAliasDialogVisible, setIsEditAliasDialogVisible] = useState(false);
  const [ dialogAddStudent, setDialogAddStudent ] = useState(false)
  const [ successPTC, setSuccessPTC ] = useState(false)
  const [ headerTitle, setHeaderTitle ] = useState(null)
  const [ dialogError, setDialogError ] = useState(false)
  const [ unAuthenticated, SetUnAuthenticated ] = useState(false)
  const [ dialogErrorMessage, setDialogErrorMessage ] = useState('')
  const [ listAttendance, setListAttendance ] = useState(null)
  const [ listStudentAttendance, setListStudentAttendance ] = useState(null)
  const [ newListStudentAttendance, setNewListStudentAttendance] = useState(listStudentAttendance)
  const [ listStudent, setListStudent] = useState(null)
  const [ listStudentClass, setListStudentClass] = useState(null)
  const [ listAttendanceModify, setListAttendanceModify ] = useState(null)
  const [ listPTCModify, setListPTCModify ] = useState(null)
  const [ listRoomHistory, setListRoomHistory ] = useState(null)
  const [ lengthSession, setLengthSession ] = useState(0)
  const [ lengthPTC, setLengthPTC ] = useState(0)
  const [ detailClass, setDetailClass] = useState({
    id: id,
    kodeKelas: '',
    namaKelas: '',
    jadwalMulai: '',
    tipeKelas: '',
    namaGuru: localStorage.getItem('teacherName'),
    statusKelas: ''
  })
  const [ detailClassRoom, setDetailClassRoom ] = useState({
    namaRoom: '',
    linkRoom: '',
    passCode: '',
    meetingID: '',
    hostKey: '',
  })
  const [ detailRequestClassRoom, setDetailRequestClassroom ] = useState({
    mulaiKelas: new Date(),
    durasi: 1,
    idKelas: id
  })
  const [ startVideo, setStartVideo ] = useState(false)

  const [ detailSession, setDetailSession ] = useState({
    indexSesi: null,
    jadwalSesi: null,
    classId: id,
  })

  const [ reviewAttendanceDetail, setReviewAttendanceDetail ] = useState({
    attendanceId: null,
    sessionIndex: null,
    newStatus: null,
    review: '',
  })

  const optionAttendancePTC = ['Hadir', 'Belum PTC', 'Tidak Hadir']

  const [ jadwalPTC, setJadwalPTC ] = useState({
    status: '',
    date: null,
    index: null
  })

  const [ loading, setLoading ] = useState(false)

  const showDialogAliasLink = () => {
    setIsEditAliasDialogVisible(true);
    setClassAliasInput(classAlias);
  }

  const getAllStudents = async () => {
    const optionGetStudent = {
      method: 'get',
      url: `${BASE_API_URL}/students`,
      headers: {
        'Authorization': localStorage.getItem("accessToken"),
        'Content-Type': 'application/json'
      }
    }

    await axios.request(optionGetStudent)
    .then((response) => {
      if (response.data.length > 0) {
        const studentsModify = response.data.map((student) => {
          student.identity = `(${student.nie}) ${student.nama}`
          return student
        });

        setListStudent(studentsModify);
      } else {
        setListStudent(response.data)
      }
      
    })
    .catch((error) => {
      console.log(error)
      if (error.response.status === 403) 
        SetUnAuthenticated(true)
      else {
        setHeaderTitle("Gagal load list Siswa")
        setDialogErrorMessage(error.response.data.message)
        setLoading(false)
        setDialogError(true)
      }
    })
  }

  const getListAttendanceClass = async () => {
    const options = {
        method: 'get',
        url: `${BASE_API_URL}/class/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${localStorage.getItem("accessToken")}`
        }
    }

    const getDetailClass = await axios.request(options)
    const students = []
    const listStudent = getDetailClass.data.murid
    listStudent.map((student) => {
      students.push(student._id)
    })
    setListStudentClass(students)
    
    setDetailClass((currentData) => ({
      ...currentData,
      kodeKelas: `${getDetailClass.data.kodeBatch}.${getDetailClass.data.kodeKelas}`,
      namaKelas: getDetailClass.data.namaKelas,
      jadwalMulai: formattedDate(getDetailClass.data.jadwal),
      tipeKelas: getDetailClass.data.jenisKelas,
      statusKelas: getDetailClass.data.statusKelas
    }))
    
    const infoDetailClass = getDetailClass.data.absensi
    setListRoomHistory(getDetailClass.data.historyZoom)
    setListAttendance(infoDetailClass)

    const dataClass = {
      attendanceId: infoDetailClass
    }
    
    const allStudentAttendance = await axios.post(
      `${BASE_API_URL}/attendanceByIds`, 
      dataClass
    )

    setListStudentAttendance(allStudentAttendance.data)

    if (allStudentAttendance.data.length !== null && allStudentAttendance.data.length > 0) {
      setLengthSession(allStudentAttendance.data[0].session.length)
    }

    modifyDataAttendance(allStudentAttendance.data)

    const attendancePTC = allStudentAttendance.data

    if (attendancePTC.length > 0 && attendancePTC[0].sessionPTC !== undefined) {
      if (attendancePTC[0].sessionPTC.length > 0) {
        setStartPTC('block')
        modifyDataPTC(attendancePTC)
        setLengthPTC(attendancePTC[0].sessionPTC.length || 0)
      }
    } else {
      setStartPTC('none')
    }
  }

  const modifyDataPTC = async (dataPTC) => {
    const data = []
  
    if (dataPTC != null && Array.isArray(dataPTC)) {
      dataPTC.map((row) => {
        const generalData = {
          id: row._id,
          nama: row.studentName,
          kelanjutan: row.kelanjutan,
          alasanBerhenti: row.alasanBerhenti,
          jumlahPTC: row.sessionPTC.length
        }

        const PTC = row.sessionPTC || []

        if (PTC.length > 0) {
          for(let i = 0; i < PTC.length; i++) {
            const detailSesiPTC = PTC[i]
            generalData[`jadwalPTC${i+1}`] = detailSesiPTC.scheduledDate
            generalData[`kehadiranPTC${i+1}`] = detailSesiPTC.status
            generalData[`reviewPTC${i+1}`] = detailSesiPTC.review
          }
        }
  
        data.push(generalData)
        return row;
      })
    }

    await setListPTCModify(data)
  }

  const fetchClassAlias = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/alias-class/${id}`);
      if (response.data) {
        setClassAlias(response.data);
      }
    } catch (error) {
      console.error('Error fetching class alias:', error);
    }
  };

  const updateClassAlias = async () => {
    setIsEditAliasDialogVisible(false);
    setLoading(true);

    try {
      const response = await axios.post(`${BASE_API_URL}/create-aliasclass/${id}`, { aliasClass: classAliasInput });
      setClassAlias(response.data.aliasClass);
      setLoading(false);
    } catch (error) {
      setDialogError(true);
      setLoading(false);
      setDialogErrorMessage(error.response.data.message);
      setHeaderTitle("Error");
      fetchClassAlias();
    }
  };

  const modifyDataAttendance = async (dataAttendance) => {
    const data = []
    dataAttendance.map((row) => {
      const generalData = {
        id: row._id,
        nie: row.studentNIE,
        student: row.studentName,
      }

      for(let i = 0; i < row.session.length; i++){
        generalData['sesi'+row.session[i].sesi] = row.session[i].status
        generalData['tanggalSesi'+row.session[i].sesi] = row.session[i].scheduledDate
        generalData['review'+row.session[i].sesi] = row.session[i].review
        generalData['statusGaji'+row.session[i].sesi] = row.session[i].digaji
      }
      
      data.push(generalData)
    })

    await setListAttendanceModify(data)
  }

  const getClassRoom = async () => {
    const optionsRoomClass = {
      method: 'get',
      url: `${BASE_API_URL}/rooms/find/${id}`,
      headers: {
        'Authorization': localStorage.getItem('accessToken')
      }
    }

    await axios.request(optionsRoomClass)
     .then((response) => {
        if (!response.data.error && response.data.classRoom.length > 0){ 
          const classroom = response.data.classRoom[0]
          
          setDetailClassRoom({
            namaRoom: classroom.namaRoom,
            linkRoom: classroom.linkRoom,
            passCode: classroom.passCode,
            meetingID: classroom.meetingID,
            hostKey: classroom.hostKey
          })
          setStartVideo(true)
        }
     })
     .catch((error) => {
        if (error.response.status === 403) 
          SetUnAuthenticated(true)
        else {
          setHeaderTitle("Gagal load list Siswa")
          setDialogErrorMessage(error.response.data.message)
          setLoading(false)
          setDialogError(true)
        }
     })
  }

  const generateClassRoom = async () => {
    setLoading(true)
    const dataGenerateClassRoom = detailRequestClassRoom

    const optionsGenerateClassRoom = {
      method: 'put',
      url: `${BASE_API_URL}/booking-room`,
      headers: {
        'Authorization': localStorage.getItem('accessToken')
      },
      data: dataGenerateClassRoom
    }

    await axios.request(optionsGenerateClassRoom)
     .then(() => {
      refreshTable()
     })
     .catch((error) => {
      if (error.status === 403) 
        SetUnAuthenticated(true)
      else {
        setHeaderTitle("Gagal Mendapatkan Ruangan Kelas")
        setDialogErrorMessage(error.response.data.message)
        setLoading(false)
        setDialogError(true)
      }
     })
    
    hideDialog('ClassRoom')
    setLoading(false)
  }

  const dataAttendanceProcess = (dataListAttendance) => {
    const documentStyle = getComputedStyle(document.documentElement);
    const sesi = []
    let terlaksana = 0
    let belumTerlaksana = 0
    let statusAttendance = {
      sakit: 0,
      izin: 0,
      tidakHadir: 0,
      hadir: 0,
      belumDiabsen: 0,
    }

    if (Array.isArray(dataListAttendance)) {
      dataListAttendance.map((data) => {
        for (let i = 1; i <= lengthSession; i++) {
          if (data[`sesi${i}`] !== "Belum diabsen" && !sesi.find((data) => data === i )) {
            terlaksana++
            sesi.push(i)
          }

          switch (data[`sesi${i}`]) {
            case "Hadir":
              statusAttendance['hadir'] = statusAttendance['hadir'] + 1
              break

            case "Tidak hadir":
              statusAttendance['tidakHadir'] = statusAttendance['tidakHadir'] + 1
              break
            
            case "Sakit":
              statusAttendance['sakit'] = statusAttendance['sakit'] + 1
              break

            case "Izin":
              statusAttendance['izin'] = statusAttendance['izin'] + 1
              break
          
            default:
              statusAttendance['belumDiabsen'] = statusAttendance['belumDiabsen'] + 1
              break
          }
        }
      })
      belumTerlaksana = lengthSession - terlaksana
    }

    const dataSession = {
      labels: ['Terlaksana', 'Belum Terlaksana'],
      datasets: [
        {
          data: [terlaksana, belumTerlaksana],
          backgroundColor: [
              documentStyle.getPropertyValue('--blue-500'), 
              documentStyle.getPropertyValue('--yellow-500'), 
              
          ],
          hoverBackgroundColor: [
              documentStyle.getPropertyValue('--blue-400'), 
              documentStyle.getPropertyValue('--yellow-400'),
          ]
        }
      ]
    }

    const optionsSession = {
      plugins: {
        legend: {
            labels: {
                usePointStyle: true
            }
        },
        title: {
          display: true,
          text: "Grafik Sesi",
          font: {
            size: 18,
            weight: 'bold',
            color: 'black'
          }
        }
      }
    };

    const dataAttendance = {
      labels: ['Hadir', 'Izin', 'Sakit', 'Tidak hadir'],
      datasets: [
        {
          data: [
            statusAttendance.hadir, 
            statusAttendance.izin, 
            statusAttendance.sakit, 
            statusAttendance.tidakHadir,
          ],

          backgroundColor: [
              documentStyle.getPropertyValue('--blue-500'), 
              documentStyle.getPropertyValue('--yellow-500'), 
              documentStyle.getPropertyValue('--surface-500'), 
              documentStyle.getPropertyValue('--red-500'), 
          ],

          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--blue-400'), 
            documentStyle.getPropertyValue('--yellow-400'), 
            documentStyle.getPropertyValue('--surface-400'), 
            documentStyle.getPropertyValue('--red-400'), 
          ]
        }
      ]
    }
    
    const optionsAttendance = {
      cutout: '40%',
      plugins: {
        legend: {
          labels: {
              usePointStyle: true
          },
          position: 'bottom'
        },
        title: {
          display: true,
          text: "Grafik Kehadiran",
          font: {
            size: 18,
            weight: 'bold',
            color: 'black'
          }
        }
      }
    };

    setChartSessionData(dataSession)
    setChartSessionOption(optionsSession)
    setChartAttendanceData(dataAttendance)
    setChartAttendanceOption(optionsAttendance)
  }

  useEffect(() => {
    try {
      getAllStudents()
      getListAttendanceClass()
      getClassRoom()
      fetchClassAlias()
    } catch (err) {
      SetUnAuthenticated(true)
    }
  }, [])

  useEffect(() => {
    dataAttendanceProcess(listAttendanceModify)
    setLengthSession(Array.isArray(listAttendanceModify) ? lengthSession : 0)
  }, [listAttendanceModify])

  useEffect(() => {
    setTimeout(() => {
      setCopySuccess('')
    }, 3000)
  }, [copySuccess])

  useEffect(() => {
    setTimeout(() => {
      setCopyAliasSuccess('')
    }, 3000)
  }, [copyAliasSuccess])

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={() => refreshTable()} className={styles.toolsList}/>;
  const paginatorRight = (dtRef) => <Button type="button" icon="pi pi-download" text className={styles.toolsList} onClick={() => exportCSV(false, dtRef)} />;

  
  const exportCSV = (selectionOnly, refDT) => {
    refDT.current.exportCSV({selectionOnly})
  }

  const refreshTable = () => {
    getListAttendanceClass()
    getClassRoom()
  }

  const hideDialog = (dialogName) => {
    if(dialogName === 'Row') setRowDialog(false)
    if(dialogName === 'Error') setDialogError(false)
    if(dialogName === 'Success') {
      setSuccessPTC(false)
      window.location.href= '#PTC'
    }

    if(dialogName === 'AddStudent') {
      setDialogAddStudent(false)
      setSelectedStudent({
        id: null,
        name: null
      })
    }

    if(dialogName === 'ClassRoom') {
      setDetailRequestClassroom((currentData) => ({
        ...currentData,
        durasi: 0,
        mulaiKelas: null
      }))
      setHeaderTitle(null)
      setRequestClassRoom(false)
    }

    if(dialogName === 'Session') {
      setSelectedCell(null)
      setReviewAttendanceDetail({
        'attendanceId': null,
        'sessionIndex': null,
        'newStatus': null,
        'review': ''
      })
      setSessionUpdateDialog(false)
    }

    if(dialogName === 'PTC') {
      setPtcSessionDialog(false)
    }

    if(dialogName === 'PTCUpdate') {
      setSelectedPtcCell(null)
      setDetailSessionPTC({
        idAttendance: null,
        index: '',
        jadwal: '',
        studentName: '',
        kehadiran: null,
        review: '',
      })

      setPtcUpdateDialog(false)
    }

    if(dialogName === 'PTCKelanjutan') {
      setSelectedPtcCell(null)
      setDetailContinuation({
        idAttendance: '',
        namaSiswa: '',
        kelanjutanSiswa: '',
        alasan: '',
      })

      setContinuation(false)
    }

    if(dialogName === 'EndClass') {
      setEndClassDialog(false)
    }

    if(dialogName === 'RemoveStudent') {
      setRemoveStudentDialog(false);
    }
  };

  const showDialogRequestClassRoom = () => {
    setDetailRequestClassroom((currentData) => ({
      ...currentData,
      mulaiKelas: new Date()
    }))

    setRequestClassRoom(true)
  }

  const handleCheckOutRoom = async () => {
    const idKelas = detailClass.id;
  
    try {
      const response = await axios.post(`${BASE_API_URL}/checkout-room`, { idKelas });
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error during checkout', error);
    }
  }

  const showDialogPTC = (dataupdate) => {
    if (dataupdate !== null) {
      const newDate = new Date(dataupdate.jadwal)

      setJadwalPTC({
        date: newDate,
        status: 'Update',
        index: dataupdate.index - 1
      })
    } else {
      setJadwalPTC({
        date: new Date(),
        status: 'Create'
      })
    }

    setPtcSessionDialog(true)
  }

  const statusAttendance = [
    {
        id: 1,
        name: 'Izin'
    },
    {
        id: 2,
        name: 'Sakit'
    },
    {
        id: 3,
        name: 'Hadir'
    },
    {
      id: 4,
      name: 'Belum diabsen'
    },
    {
      id: 5,
      name: 'Tidak hadir'
    },
  ]

  const renderHeader = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
              <p className={styles.cardTitle}>Daftar Siswa</p>
            </div>
        </div>
    );
  };

  const renderHeaderPTC = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
              <p className={styles.cardTitle}>Parent Teacher Conference</p>
            </div>
            {
              detailClass.statusKelas !== "Selesai" ?
                <div className={styles.addStudentsClass}> 
                  <button className={styles.buttonAddClass} onClick={() => showDialogPTC(null)}>Sesi PTC +</button>
                </div>
              :
                null
            }
        </div>
    );
  };

  const detailPTCChange = (event) => {
    const { name, value } = event.target

    setDetailSessionPTC((currentDetailPTC) => ({
      ...currentDetailPTC,
      [name]: value
    }))
  }

  const detailContinuationChange = (event) => {
    const { name, value } = event.target

    setDetailContinuation((detailContinuation) => ({
      ...detailContinuation,
      [name]: value
    }))
  }

  const isCellPTCSelectable = (event) => {
    if (detailClass.statusKelas !== "Selesai")
      return (event.data.field === 'nama' ? false : true)
    if (detailClass.statusKelas === "Selesai")
      return false
  }

  const isCellAttendanceSelectable = (event) => {
    if (detailClass.statusKelas !== "Selesai") 
      return (event.data.field === 'student' || event.data.field === 'nie' || event.data.field === 'action'  ? false : true)
    if (detailClass.statusKelas === "Selesai")
      return false
  }

  const sessionDetailChange = (event) => {
    const {name, value} = event.target

    setDetailSession((currentSession) => ({
      ...currentSession,
      [name]: value
    }))
  }

  const reviewAttendanceChange = (event) => {
    const {name, value} = event.target

    setReviewAttendanceDetail((currentSession) => ({
      ...currentSession,
      [name]: value
    }))
  }

  const classRoomDetailChange = (event) => {
    const {name, value} = event.target

    setDetailRequestClassroom((currentData) => ({
      ...currentData,
      [name]: value
    }))
  }

  const actionBodyTemplate = (rowdata) => {
    return (
        <Button 
          icon="pi pi-trash" 
          rounded outlined severity='danger' 
          onClick={() => deleteClassDialog(rowdata)} 
          tooltip='Pindah Kelas / Undur Diri' 
          tooltipOptions={{position:'top', hideDelay: 2000}}
        />
    )
  }

  const showDialogAddStudent = () => {
    if(selectedStudent.id === null) {
        setDialogErrorMessage('Silahkan masukkan nama siswa lebih dulu')
        setDialogError(true)
    }
    else {
      setDialogAddStudent(true)
    }
  }

  const showUpdateReviewAttendance = (event) => {
    const { rowData, column, value } = event
    const index = column.key.split('$')[1]

    setReviewAttendanceDetail((currentData) => ({
      ...currentData,
      attendanceId: rowData.id,
      sessionIndex: index,
      newStatus: value,
      review: rowData[`review${index}`]
    }))
    
    setSessionUpdateDialog(true)
  }

  const footerDialogError = (
    <div className='text-center mt-0'>
      <Button label="Ok" onClick={() => hideDialog('Error')} autoFocus />
    </div>
  )

  const footerDialogAddStudent = (
    <div>
        <Button label="No" icon="pi pi-times" onClick={() => hideDialog('AddStudent')} className="p-button-text mr-3"/>
        <Button label="Yes" icon="pi pi-check" onClick={() => addClassStudent()} autoFocus />
    </div>
  )

  const footerDialogEditAlias = (
    <React.Fragment>
      <Button label="Batal" icon="pi pi-times" onClick={() => setIsEditAliasDialogVisible(false)} className="p-button-text" />
      <Button label="Simpan" icon="pi pi-check" onClick={updateClassAlias} autoFocus />
    </React.Fragment>
  );
  

  const footerDialogSuccessPTC = (
    <div className='text-center'>
        <Button label="Ok" onClick={() => hideDialog('Success')} style={{borderRadius: '10%'}}/>
    </div>
  )

  const footerDialogUpdatePTC = (
    <div>
        <Button label="Batal" icon="pi pi-times" onClick={() => hideDialog('PTCUpdate')} className="p-button-text mr-3"/>
        <Button label="Simpan Perubahan" icon="pi pi-check" onClick={() => updatePTC()} autoFocus />
    </div>
  )

  const footerDialogContinuation = (
    <div>
        <Button label="Batal" icon="pi pi-times" onClick={() => hideDialog('PTCKelanjutan')} className="p-button-text mr-3"/>
        <Button label="Simpan Perubahan" icon="pi pi-check" autoFocus onClick={() => updateContinuation()} />
    </div>
  )

  const footerDialogUpdateStatus = (
    <div>
        <Button label="No" icon="pi pi-times" onClick={() => hideDialog('Session')} className="p-button-text mr-3"/>
        <Button label="Yes" icon="pi pi-check" onClick={() => updateStatus()} autoFocus />
    </div>
  )

  const footerDialogPTC = (
    <div>
        <Button label="Batal" icon="pi pi-times" onClick={() => hideDialog('PTC')} className="p-button-text mr-3"/>
        <Button label="Simpan" icon="pi pi-check" autoFocus onClick={() => createUpdatePTCSchedule()}/>
    </div>
  )

  const createUpdatePTCSchedule = () => {
    if (jadwalPTC.status === "Update") updateSchedulePTC()
    if (jadwalPTC.status === "Create") createPTC()
  }

  const checkPTCReview = (attendanceID) => {
    let result = false;
    const dataPTC = listPTCModify.filter((dataPTC) => dataPTC.id === attendanceID)[0]
    
    for (let i = 0; i < dataPTC.jumlahPTC; i++) {
      if(dataPTC[`kehadiranPTC${i+1}`] === 'Hadir' && dataPTC[`reviewPTC${i+1}`] !== '-'){
        result = true;
      } else {
        result = false;
        break;
      }
    }
    return result
  }

  const showPtcUpdate = (element) => {
    const { rowData, cellIndex, column } = element
    const indexPTC = cellIndex
    
    if (column.props.header === "Kelanjutan") {
        setDetailContinuation({
          idAttendance: rowData.id,
          namaSiswa: rowData.nama,
          kelanjutanSiswa: rowData.kelanjutanSiswa,
          alasan: rowData.alasan
        })
  
        setContinuation(true)
    } else {
      setDetailSessionPTC({
        idAttendance: rowData.id,
        index: indexPTC,
        studentName: rowData.nama,
        jadwal: rowData[`jadwalPTC${indexPTC}`],
        review: rowData[`reviewPTC${indexPTC}`],
        kehadiran: rowData[`kehadiranPTC${indexPTC}`]
      })
  
      setPtcUpdateDialog(true)
    }
  }

  const createPTC = async () => {
    hideDialog('PTC')
    setLoading(true)

    const dataCreatePTC = {
      attendanceId: listAttendance,
      jadwal: jadwalPTC.date
    } 

    const optionsCreatePTC = {
      method: 'post',
      url: `${BASE_API_URL}/add-array-ptc`,
      data: dataCreatePTC,
      headers: {
        'Authorization': localStorage.getItem('accessToken')
      },
    }

    await axios.request(optionsCreatePTC)
      .then(() => {
        setLoading(false)
        refreshTable()
        setSuccessPTC(true)
      })
      .catch((error) => {
        setHeaderTitle("Gagal membuat sesi PTC")
        setDialogErrorMessage(error)
        setLoading(false)
        setDialogError(true)
      })
  }

  const updateSchedulePTC = async () => {
    hideDialog('PTC')
    setLoading(true)

    const dataSchedulePTC = {
      classId: id, 
      jadwalSesi: jadwalPTC.date,
      indexSesi: jadwalPTC.index
    }

    const optionUpdatePTC = {
      method: 'put',
      url: `${BASE_API_URL}/edit-class-ptc-schedule`,
      headers: {
        'Authorization': localStorage.getItem('accessToken')
      },
      data: dataSchedulePTC
    }

    await axios.request(optionUpdatePTC)
      .then(() => {
        setLoading(false)
        refreshTable()
      })
      .catch((error) => {
        setHeaderTitle("Gagal rescheedule PTC")
        setDialogErrorMessage(error)
        setLoading(false)
        setDialogError(true)
      })
  }

  const updatePTC = async () => {
    hideDialog('PTCUpdate')
    setLoading(true)

    const dataUpdatePTC = {
      indexPTC: detailSessionPTC.index - 1,
      statusPTC: detailSessionPTC.kehadiran,
      reviewPTC: detailSessionPTC.review,
      attendanceId: detailSessionPTC.idAttendance
    }

    const optionsUpdatePTC = {
      method: 'put',
      url: `${BASE_API_URL}/edit-review-status-ptc`,
      data: dataUpdatePTC,
      headers: {
        'Authorization': localStorage.getItem('accessToken')
      },
    }

    await axios.request(optionsUpdatePTC)
      .then(() => {
        setLoading(false)
        refreshTable()
      })
      .catch((error) => {
        setHeaderTitle("Gagal Mengupdate sesi PTC")
        setDialogErrorMessage(error)
        setLoading(false)
        setDialogError(true)
      })
  }

  const updateContinuation = async () => {
    hideDialog('PTCKelanjutan')
    setLoading(true)

    const dataUpdateContinuation = {
      kelanjutanSiswa: detailContinuation.kelanjutanSiswa,
      attendanceId: detailContinuation.idAttendance,
      alasan: detailContinuation.kelanjutanSiswa === "Berhenti" ? detailContinuation.alasan : ""
    }

    const optionsUpdateContinuation = {
      method: 'post',
      url: `${BASE_API_URL}/lanjut-siswa`,
      data: dataUpdateContinuation,
      headers: {
        'Authorization': localStorage.getItem('accessToken')
      },
    }

    await axios.request(optionsUpdateContinuation)
      .then(() => {
        setLoading(false)
        refreshTable()
      })
      .catch((error) => {
        setHeaderTitle("Gagal Mengupdate RR Kelas")
        setDialogErrorMessage(error)
        setLoading(false)
        setDialogError(true)
      })
  }

  const addClassStudent = async () => {
    hideDialog('AddStudent')
    setLoading(true)
    const dataStudent = {
      studentId: selectedStudent.id
    }

    const optionAddClassStudent = {
      method: 'put',
      url: `${BASE_API_URL}/class/${id}/add-student`,
      data: dataStudent
    }

    const addStudent = await axios.request(optionAddClassStudent)
      .then((response) => {

      })
      .catch((error) => {
        setDialogErrorMessage('Siswa dengan nama ' + selectedStudent.name.nama + ' sudah terdaftar')
        setDialogError(true)
      })
    refreshTable()
    setLoading(false)
  }

  const deleteClassDialog = (rowData) => {
    setRemoveStudentDialog(true);
    setDetailAttendance(rowData);
  }

  const attendanceSession = () => {
    const column = []

    if (listAttendanceModify !== null && listAttendanceModify.length > 0) {
      for (let index = 1; index <= lengthSession; index++) {
        column.push(<Column key={index} field={'sesi'+index} header={() => headerColumnBody(index, listAttendanceModify[0][`tanggalSesi${index}`])} 
                    style={{minWidth: '10rem', textAlign: 'center'}}></Column>)
      }
    }
    
    return column
  }

  const attendancePTC = () => {
    const column = []

    if (listPTCModify !== null && listPTCModify.length > 0) {
      for (let index = 1; index <= lengthPTC; index++) {
        column.push(<Column key={index} header={(e) => headerPTCColumn(e, index)} body={(e) => bodyPTC(e, index)} style={{minWidth: '20rem', width: '25rem'}}></Column>)
      }
    }
    
    return column
  }

  const endClass = async () => {
    setLoading(true)
    setEndClassDialog(false)

    let statusKelanjutan = true
    for (let i = 0; i < listPTCModify.length; i++) {
      if (listPTCModify[i].kelanjutan === "Belum Selesai") {
        statusKelanjutan = false
        break
      }
    }

    if (startPTC !== 'none' && statusKelanjutan) {
      const optionsEndClass = {
        method: 'put',
        url: `${BASE_API_URL}/end-class`,
        headers: {
          'Authorization': localStorage.getItem('accessToken')
        },
        data: {classId: id}
      }

      await axios.request(optionsEndClass)
        .then(() => {
          setDetailClass((currentData) => ({
            ...currentData,
            statusKelas: "Selesai"
          }))

          setLoading(false)
        })
        .catch((error) => {
          setHeaderTitle("Gagal Mengakhiri Kelas")
          setDialogErrorMessage(error.response.data.message)
          setLoading(false)
          setDialogError(true)
        })
    } else {
      setHeaderTitle("Gagal Mengakhiri Kelas")
      setDialogErrorMessage("PTC dan Status Kelanjutan belum tuntas, sehingga belum bisa mengakhiri kelas.")
      setLoading(false)
      setDialogError(true)
    }
  }

  const headerPTCColumn = (element, index) => {
    const dataColumn = {
      jadwal: element.props.value[0][`jadwalPTC${index}`],
      index: index,
    }

    return (
      <div className={styles.headerTablePTC}>
        PTC {index}
        {
          detailClass.statusKelas !== "Selesai" ? 
            <Button icon="pi pi-pencil" severity='secondary' style={{borderRadius: '50%'}} onClick={() => showDialogPTC(dataColumn)}></Button>
          :
            null
        }
    </div>
    )
  }

  const bodyPTC = (rowData, index) => {
    return (
      <div className={styles.ptcField}>
        <p style={{fontSize: '13px', margin: 0}}><strong>Tgl: </strong></p>
        <div className={styles.timeAndLabel}>
          <p style={{fontSize: '12px', margin: 0}}>{formattedDateWithDay(rowData[`jadwalPTC${index}`])}</p>
          <Tag value={rowData[`kehadiranPTC${index}`]} severity={getSeverity(rowData[`kehadiranPTC${index}`], "attendancePTC")} />
        </div>
        <div>
          <p style={{fontSize: '13px', margin: 0}}><strong>Review Testimonial Parents: </strong></p>
          <p style={{fontSize: '12px', margin: 0}}>{rowData[`reviewPTC${index}`]}</p>
        </div>
      </div>
    )
  } 

  const headerColumnBody = (index, scheduleDate) => {
    return (
      <div className='text-center'>
        Sesi {index}
        <p style={{fontSize: '15px', fontWeight: 'lighter', margin: 0}}>{formattedDate2(scheduleDate)}</p>
        {
          (detailClass.statusKelas !== "Selesai" && !listAttendanceModify[0][`statusGaji${index}`]) ?
            <span><i className='pi pi-pencil' style={{fontSize: '12px', color: 'blue'}} onClick={() => showUpdateScheduleDialog(index, scheduleDate)}></i></span>
          : null
        }
      </div>
    )
  }

  const showUpdateScheduleDialog = (index, scheduleDate) => {
    setScheduleUpdateDialog(true)
    setDetailSession({
      indexSesi: index-1,
      classId: id,
      jadwalSesi: stringToDate(scheduleDate)
    })
  }

  const sessionDialogFooter = () => (
    <React.Fragment>
        <Button label="Batal" icon="pi pi-times" outlined onClick={() => setScheduleUpdateDialog(false)} className='mr-3'/>
        <Button label="Simpan" icon="pi pi-check" onClick={() => updateScheduleSession()}/>
    </React.Fragment>
  )

  const classRoomDialogFooter = () => (
    <React.Fragment>
        <Button label="Batal" icon="pi pi-times" outlined onClick={() => hideDialog('ClassRoom')} className='mr-3'/>
        <Button label="Generate" icon="pi pi-check" onClick={() => generateClassRoom()}/>
    </React.Fragment>
  )

  const endClassDialogFooter = () => (
    <React.Fragment>
        <Button label="Batal" icon="pi pi-times" outlined onClick={() => hideDialog('EndClass')} className='mr-3'/>
        <Button label="Akhiri" icon="pi pi-check" severity='danger' onClick={() => endClass()}/>
    </React.Fragment>
  )

  const updateStatus = async (rowData, statusSelected) => {
    setLoading(true)
    const dataAttendance = reviewAttendanceDetail
    
    const optionUpdateAttendance = {
      method: 'post',
      url: `${BASE_API_URL}/editStatus`,
      data: dataAttendance
    }

    const updateAttendance = await axios.request(optionUpdateAttendance)
    
    refreshTable()
    setSessionUpdateDialog(false)
    setSelectedCell(null)
    setLoading(false)
  }

  const updateScheduleSession = async () => {
    setScheduleUpdateDialog(false)
    setLoading(true)
    const dataSession = detailSession

    const optionSchedule = {
      method: 'put',
      url: `${BASE_API_URL}/editScheduleDate`,
      headers: {
        'Content-Api': 'application/json'
      },
      data: dataSession
    }

    await axios.request(optionSchedule)
      .then((response) => {
        if (response.status === 200) getListAttendanceClass()
      })
      .catch((error) => {
        console.log(error)
      })
    
    setLoading(false)
  }

  const redirectLinkMeeting = () => {
    const url = detailClassRoom.linkRoom
    window.open(url, '_blank')
  }

  const clipBoardCopy = (refContent) => {
    navigator.clipboard.writeText(refContent.current.href)
    
    setCopySuccess('Copied!')
  }

  const clipBoardCopyAlias = (refContent) => {
    navigator.clipboard.writeText(refContent.current.href)
    
    setCopyAliasSuccess('Copied!')
  }

  const statusKelanjutan = (rowData) => {
    let studentContinuation = "PTC Belum Selesai"
    if (rowData.kelanjutan === "Berhenti" || rowData.kelanjutan === "Lanjut" ) studentContinuation = rowData.kelanjutan

    return (
      <div>
        <Tag value={studentContinuation} severity={getSeverity(studentContinuation, "kelanjutan")} className={styles.labelContinuation} />
        <p style={{marginTop: '10px', fontSize: '12px'}}>{rowData.alasanBerhenti || ""}</p>
      </div>
    )
  }

  const endClassButton = () => {
    if (detailClass.statusKelas !== "Selesai" && startPTC === "block") {
     return (
        <div className='mt-3 flex justify-content-center'>
          <Button key='0' label='Akhiri Kelas' severity='danger' className={styles.endClassButton} onClick={() => setEndClassDialog(true)}/>
        </div>
      )
    }
  }

  const endClassBadge = () => {
    if (detailClass.statusKelas === "Selesai") {
      return (
        <div className='mb-3 w-100'>
          <button disabled className={styles.endClassButtonDisabled}>  
            -- Kelas Telah Berakhir --
          </button>
        </div>
      )
    }
  }

  const youtubeRecordingLink = (data) => {
    if (data.linkYoutube !== undefined) return (
      <a href={data.linkYoutube} target='_blank' style={{textDecoration: 'none'}}><Button label={data.linkZoom} icon="pi pi-youtube" severity='danger' className={styles.buttonRecord}/></a>
    ); else {
      return (
        ""
      );
    }
  }

  const isUrl = (data) => {
    let pattern = /[/?$%^&*)(><#@!., ]/g;
    let result = data.replace(pattern, "-");
    setClassAliasInput(result);
  }

  return (
    <Page>
        {endClassBadge()}
        <Toast ref={toast}/>
        <RemoveClassModal visible={removeStudentDialog} visibleHandler={() => hideDialog("RemoveStudent")} setLoading={setLoading} toast={toast} refreshAction={() => getListAttendanceClass()} detailAttendance={detailAttendance}/>
        <Card>
            <Card.Body>
                <div className={styles.inlineCard}>
                    <div className={styles.detailClassLayout}>
                        <h4>Detail Kelas {detailClass.namaKelas} {detailClass.kodeKelas}</h4>
                        <table className={styles.tableLayout}>
                            <tbody>
                                <tr>
                                    <td className={styles.infoTitle}>Subject Kelas</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{detailClass.namaKelas}</td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Jadwal Rilis</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{detailClass.jadwalMulai}</td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Jenis Kelas</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{detailClass.tipeKelas}</td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Nama Guru</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{detailClass.namaGuru}</td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Group WA</td>
                                    <td className={styles.separatial}>:</td>
                                    <td><a href='#'><button>Coming Soon!</button></a></td>
                                </tr>
                                {
                                  (detailClass.namaKelas === "IC Atas" || detailClass.namaKelas === "IC Bawah") && (
                                      <tr>
                                          <td className={styles.infoTitle}>FeedBack Kelas</td>
                                          <td className={styles.separatial}>:</td>
                                          <td><a href="https://forms.gle/qPLVJUdse63pVSL27" target="_blank">Feedback Form</a></td>
                                      </tr>
                                  )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.chartInline}>
                      <Chart type='pie' data={chartSessionData} options={chartSessionOption} className='md:w-16rem' />
                      <Chart type='doughnut' data={chartAttendanceData} options={chartAttendanceOption} className='md:w-17rem' />
                    </div>
                </div>
                <div style={{marginTop: '10px'}} className='flex'>
                  {
                    !startVideo ? 
                      <div className={styles.startMeeting}>
                        <Button icon="pi pi-bookmark-fill" style={{borderRadius: '10px'}} label="Materi" severity='success'/>
                        <Button icon="pi pi-video" style={{borderRadius: '10px'}} label='Generate Room' className={detailClass.statusKelas === "Selesai" ? styles.hiddenElement : ""}                          
                          onClick={() =>showDialogRequestClassRoom()}></Button>  
                      </div>  
                      :
                      <div className={styles.startMeeting}>
                        <Button icon="pi pi-bookmark-fill" style={{borderRadius: '10px'}} label="Materi" severity='success'/>
                        <Button icon="pi pi-video" style={{borderRadius: '10px'}} outlined label='Masuk Kelas' onClick={() => redirectLinkMeeting()}></Button>
                        <Button icon="pi pi-sign-out" style={{borderRadius: '10px', marginLeft: '5px'}} label='End Room' className='p-button-danger' onClick={handleCheckOutRoom} />
                        <p>Room : <strong>{detailClassRoom.namaRoom}</strong></p>
                        <p>Meeting ID: <strong>{detailClassRoom.meetingID}</strong></p>
                        <p>Passcode: <strong>{detailClassRoom.passCode}</strong></p>
                        <p>Host Key: <strong>{detailClassRoom.hostKey}</strong></p>
                      </div>
                  }
                </div>
                <div className={styles.inlineContent}>
                    <p>Link Alias :</p>
                    {classAlias === "" && <Button icon="pi pi-cog" label="Buat link alias" iconPos='right' outlined severity="info" className='border-round py-1' onClick={() => showDialogAliasLink()} />}
                    {classAlias !== "" && (
                      <>
                        <a href={`/edufic-room/${classAlias}`} ref={clipBoardAlias}>{`${window.location.host}/edufic-room/`}</a>
                        <Button icon="pi pi-pencil" iconPos='right' outlined label={classAlias} className={styles.editAliasButton} onClick={() => showDialogAliasLink()} />
                        <Button icon="pi pi-copy" label='Copy' style={{borderRadius: '10px', height: '30px', backgroundColor: 'var(--surface-400)', border: 'none'}} onClick={() => clipBoardCopyAlias(clipBoardAlias)}/>
                      </>
                    )}
                    <p style={{fontWeight: 'normal', color: 'grey'}}>{copyAliasSuccess}</p>
                </div>

                <div className={styles.linkRoomAndPTC}>
                  <div className={styles.inlineContent}>
                    <p>Link Kelas :</p>
                    <a href={`/edufic-room/${id}`} ref={clipBoardLink}>{`${window.location.host}/edufic-room/${id}` }</a>
                    <Button icon="pi pi-copy" label='Copy' style={{borderRadius: '10px', height: '30px', backgroundColor: 'var(--surface-400)', border: 'none'}} onClick={() => clipBoardCopy(clipBoardLink)}/>
                    <p style={{fontWeight: 'normal', color: 'grey'}}>{copySuccess}</p>
                  </div>

                  { startPTC === 'none' ? 
                    <button className={styles.buttonPTC} onClick={() => showDialogPTC(null)}>
                      <img src={IconFamily} width={20} alt='Family'/>
                      PTC
                    </button>
                    : 
                    <button disabled className={styles.buttonPTCOutline}>
                      PTC Berlangsung
                    </button>
                  }
                </div>
            </Card.Body>
        </Card>

        <Card className='mt-3'>
          <Card.Body>
            <div>
              <div className={styles.tableContainer}>
                <DataTable value={listAttendanceModify} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                  id='studentAttendanceTable' rowsPerPageOptions={[5, 10, 25, 50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight(dtPresence)} header={renderHeader()}
                  dataKey='_id' cellSelection selectionMode='single' selection={selectedCell} onSelectionChange={(e) => setSelectedCell(e.value)} metaKeySelection={false}
                  onCellSelect={(e) => showUpdateReviewAttendance(e)} scrollable ref={dtPresence} isDataSelectable={isCellAttendanceSelectable}
                >
                    {
                      detailClass.statusKelas !== "Selesai" ? <Column frozen field="action" body={actionBodyTemplate}></Column> : null
                    }
                    <Column frozen field="student" sortable header="Nama Siswa" style={{minWidth: '14rem'}}></Column>  
                    <Column field="nie" sortable header="NIE" style={{minWidth: '5rem'}}></Column>  
                    {attendanceSession()}
                </DataTable>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card className='mt-3' style={{display: startPTC}} id='PTC'>
          <Card.Body>
            <div>
              <div className={styles.tableContainer}>
                <DataTable value={listPTCModify} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                  id='studentAttendanceTable' rowsPerPageOptions={[5, 10, 25, 50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight(dtPTC)} 
                  header={renderHeaderPTC()} dataKey='_id' scrollable ref={dtPTC} cellSelection selectionMode='single' selection={selectedPtcCell} 
                  onSelectionChange={(e) => setSelectedPtcCell(e.value)} metaKeySelection={false} onCellSelect={(e) => showPtcUpdate(e)} isDataSelectable={isCellPTCSelectable}
                >
                    <Column frozen field="nama" sortable header="Nama Siswa" style={{minWidth: '15rem'}}></Column>  
                    
                    {attendancePTC(listPTCModify)}
                    <Column sortable field='kelanjutan' header="Kelanjutan" body={statusKelanjutan} style={{minWidth: '10rem', textAlign:'center'}}></Column>
                </DataTable>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card className='mt-3'> 
          <Card.Body>
            <div>
              <div className={styles.tableContainer}>
                <DataTable value={listRoomHistory} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                  id='studentAttendanceTable' rowsPerPageOptions={[5, 10, 25, 50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight(dtRoomHistory)} header="Riwayat Room"
                  dataKey='_id' scrollable ref={dtRoomHistory}
                >
                    <Column frozen field="linkZoom" sortable header="Nama Room" style={{minWidth: '10rem'}}></Column> 
                    <Column field="jadwalKelas" sortable header="Mulai Kelas" body={(row) => formattedDate(row.jadwalKelas)} style={{minWidth: '12rem'}}></Column> 
                    <Column field="akhirKelas" sortable header="Kelas Berakhir" body={(row) => formattedDate(row.akhirKelas)} style={{minWidth: '12rem'}}></Column> 
                    <Column field="linkYoutube" sortable header="Recording" body={(row) => youtubeRecordingLink(row)} style={{minWidth: '12rem'}}></Column>
                </DataTable>
              </div>
            </div>
          </Card.Body>
        </Card>
        
        {endClassButton()}

        <Dialog visible={isEditAliasDialogVisible} style={{ width: '450px' }} header="Edit Alias Kelas" onHide={() => setIsEditAliasDialogVisible(false)} footer={footerDialogEditAlias}>
          <div className='flex-auto mb-2'>
            <label htmlFor="alias" className='font-bold block mb-1'>Alias Kelas</label>
            <InputText id="alias" value={classAliasInput} type='text' className='w-full' onChange={(e) => isUrl(e.target.value)} />
          </div>
        </Dialog>
        
        <Dialog visible={dialogAddStudent} style={{ width: '30rem' }} header="Tambah Siswa" footer={footerDialogAddStudent} 
         onHide={() => hideDialog('AddStudent')}>
          <p>Yakin ingin menambahkan siswa {selectedStudent !== null ? selectedStudent.nama : 'undefined'}?</p>
        </Dialog>

        <Dialog visible={successPTC} style={{ width: '25rem' }} header="Berhasil Tambah PTC" footer={footerDialogSuccessPTC} 
         onHide={() => hideDialog('Success')}>
          <div className={styles.successPTC}>  
            <img src={SuccessPTC} alt="" width={250}/>
            <p>Yeay, berhasil menambahkan PTC!</p>
          </div>
        </Dialog>
        
        <Dialog visible={dialogError} header={headerTitle || "Gagal Tambah Siswa"} footer={footerDialogError} 
          onHide={() => hideDialog('Error')} style={{width:'30rem'}}>
            <div className='text-center mb-0'>
                <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '6rem', color: 'red' }} />
                <p style={{ fontSize: '18px'}}>{dialogErrorMessage}</p>
            </div>
        </Dialog>

        <Dialog visible={scheduleUpdateDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
          footer={sessionDialogFooter} onHide={() => setScheduleUpdateDialog(false)} header="Ubah Jadwal Sesi">
          <div className='flex-auto mb-3'>
            <label htmlFor="name" className='font-bold block mb-2'>Subjek kelas</label>
            <InputText disabled id='namaKelas' value={detailClass.namaKelas} className='w-full' required autoFocus />
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="level" className='font-bold block mb-2'>Sesi</label>
            <InputNumber disabled id='level' value={detailSession.indexSesi+1} className='w-full' required autoFocus min={1} max={12}/>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="jadwal-rilis" className='font-bold block mb-2'>Jadwal Sesi</label>
            <Calendar name='jadwalSesi' className='w-full' value={detailSession.jadwalSesi} onChange={sessionDetailChange} 
             showTime hourFormat='24' showIcon dateFormat='dd MM yy -'/>
          </div>
        </Dialog>

        <Dialog visible={requestClassRoom} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
          footer={classRoomDialogFooter} onHide={() => hideDialog('ClassRoom')} header="Pengajuan Kelas">
          <div className='flex-auto mb-3'>
            <label htmlFor="durasi" className='font-bold block mb-2'>Durasi</label>
            <div className='p-inputgroup'>
              <InputNumber name='durasi' id='durasi' value={detailRequestClassRoom.durasi} className='w-full' required  min={1.5} max={4} 
                onValueChange={classRoomDetailChange} minFractionDigits={0} maxFractionDigits={2}/>
              <span className="p-inputgroup-addon">Jam</span>
            </div>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="mulaiKelas" className='font-bold block mb-2'>Mulai Kelas</label>
            <Calendar disabled name='mulaiKelas' className='w-full' value={detailRequestClassRoom.mulaiKelas}
             showTime hourFormat='24' showIcon dateFormat='dd MM yy -'/>
          </div>
        </Dialog>

        <Dialog visible={sessionUpdateDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
          onHide={() => hideDialog('Session')} header="Update Presensi" footer={footerDialogUpdateStatus}>
          <div className='flex-auto mb-3'>
            <label htmlFor="durasi" className='font-bold block mb-2'>Status Presensi</label>
            <Dropdown name='newStatus' id='newStatus' value={reviewAttendanceDetail.newStatus} options={statusAttendance} optionValue='name'  optionLabel='name' 
              placeholder="Pilih status ..." className='w-full' onChange={reviewAttendanceChange}></Dropdown>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="mulaiKelas" className='font-bold block mb-2'>Review Siswa</label>
            <InputTextarea name='review' id='review' autoResize value={reviewAttendanceDetail.review} onChange={reviewAttendanceChange} className='w-full' />
          </div>
        </Dialog>

        <Dialog visible={ptcSessionDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
          onHide={() => hideDialog('PTC')} header="Mulai Sesi PTC" footer={footerDialogPTC}
        >
          <div className='flex-auto mb-3'>
            <label htmlFor="Jadwal Mulai" className='font-bold block mb-2'>Jadwal PTC</label>
            <Calendar name='jadwalPTC' className='w-full' value={jadwalPTC.date} 
              onChange={ (e) => setJadwalPTC((currentData) => ({ ...currentData, date: e.target.value}))}
             showTime hourFormat='24' showIcon dateFormat='dd MM yy -'/>
          </div>
        </Dialog>

        <Dialog visible={ptcUpdateDialog} style={{width: '32rem'}} breakpoints={{ '960px': '75vm', '641px': '90vw'}} 
          header={`PTC ${detailSessionPTC.index}`} footer={footerDialogUpdatePTC} onHide={() => hideDialog("PTCUpdate")}>
          <div className='flex-auto mb-3'>
            <label htmlFor="jadwal" className='font-bold block mb-2'>Jadwal</label>
            <InputText disabled name='jadwal' id='jadwal' className='w-full' value={formattedDate(detailSessionPTC.jadwal)} style={{color: 'black'}}></InputText>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="studentName" className='font-bold block mb-2'>Nama Siswa</label>
            <InputText disabled name='studentName' id='studentName' className='w-full' value={detailSessionPTC.studentName} style={{color: 'black'}}></InputText>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="kehadiran" className='font-bold block mb-2'>Kehadiran</label>
            <Dropdown name='kehadiran' id='kehadiran' options={optionAttendancePTC} value={detailSessionPTC.kehadiran}
              onChange={detailPTCChange} placeholder="Pilih status ..." className='w-full'></Dropdown>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="review" className='font-bold block mb-2'>Review Testimonial Parents</label>
            <InputTextarea name='review' id='review' value={detailSessionPTC.review} onChange={detailPTCChange} className='w-full' />
          </div>
        </Dialog>

        <Dialog visible={continuation} style={{width: '32rem'}} breakpoints={{ '960px': '75vm', '641px': '90vw'}} 
          header="Update Status Kelanjutan" footer={footerDialogContinuation} onHide={() => hideDialog("PTCKelanjutan")} >
          <div className='flex-auto mb-3'>
            <label htmlFor="nama" className='font-bold block mb-2'>Nama Murid</label>
            <InputText disabled name='jadwal' id='jadwal' className='w-full' value={detailContinuation.namaSiswa} style={{color: 'black'}}></InputText>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="kehadiran" className='font-bold block mb-2'>Status Kelanjutan</label>
            <SelectButton name='kelanjutanSiswa' id='kelanjutanSiswa' value={detailContinuation.kelanjutanSiswa} 
              onChange={detailContinuationChange} options={["Berhenti", "Lanjut"]} />
          </div>
          <div className='flex-auto mb-3' style={{ display: detailContinuation.kelanjutanSiswa === "Berhenti" ? 'block' : 'none' }}>
            <label htmlFor="review" className='font-bold block mb-2'>Alasan Berhenti</label>
            <InputTextarea name='alasan' id='alasan' value={detailContinuation.alasan} onChange={detailContinuationChange} className='w-full' />
          </div>
        </Dialog>

        <Dialog visible={endClassDialog} style={{width: '32rem'}} breakpoints={{ '960px': '75vm', '641px': '90vw'}}
          onHide={() => hideDialog('EndClass')} header='Akhiri Kelas' footer={endClassDialogFooter} 
        >  
          <div className={styles.endClassDialog}>
            <i className='pi pi-exclamation-triangle' style={{color: 'rgb(241, 255, 86)', fontSize: '8rem'}}></i>
            <p>Yakin ingin mengakhiri kelas <strong>{`${detailClass.namaKelas} - ${detailClass.kodeKelas}`}</strong> ?</p>
          </div> 
        </Dialog>

        <UnverifiedToken 
          visibility={unAuthenticated} 
          dialogTitle={"Sesi Berakhir"} 
          dialogMessage={"Silahkan logout untuk mendapatkan akses terbaru"}
        />

        <Loading visible={loading}/>
    </Page>
  )
}

export default DetailClass
