import React, {} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import ClassManagement from './pages/ClassManagement/ClassManagement';
import {PrimeReactProvider} from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css'
import DetailClass from './pages/DetailClass/DetailClass';
import SignUp from './pages/SignUp/SignUp';
import {BASE_API_URL} from './helpers';
import BlankPage from './pages/BlankPage/BlankPage';
import RevenueHistory from './pages/RevenueHistory/RevenueHistory';
import SalaryReport from './pdf/SalaryReport';
import Preference from './pages/Preference/Preference'
import ShortLink from './pages/ShortLink/ShortLink';

function App() {
    return (
        <PrimeReactProvider>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PublicRoute>
                                <Login/>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/recruitment"
                        element={
                            <PublicRoute>
                                <SignUp/>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/class-management"
                        element={
                            <PrivateRoute>
                                <ClassManagement/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/class-management/:id'
                        element={
                            <PrivateRoute>
                                <DetailClass/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/edufic-room/:id'
                        element={
                            <BlankPage/>
                        }
                    />
                    <Route
                        path='/revenue-history'
                        element={
                            <RevenueHistory/>
                        }
                    />
                    <Route
                        path='/report-salary'
                        element={
                            <SalaryReport/>
                        }
                    />
                    <Route
                        path='/Preference'
                        element={
                            <Preference/>
                        }
                    />
                    <Route
                        path='/:shortLink'
                        element={
                            <ShortLink/>
                        }
                    />
                </Routes>
            </Router>
        </PrimeReactProvider>
    );
}

const PublicRoute = ({children}) => {
    const isLoggedIn = localStorage.getItem('accessToken')
    return isLoggedIn ? <Navigate to="/dashboard" replace={true}/> : children
}

const PrivateRoute = ({children}) => {
    const isLoggedIn = localStorage.getItem('accessToken')
    return isLoggedIn ? children : <Navigate to="/" replace={true}/>
}

export default App;