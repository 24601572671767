import React from 'react'
import { Document, Font, PDFViewer, Page, StyleSheet, View } from '@react-pdf/renderer'
import { TNR, TNRB, TNRBI, TNRI } from '../Font/font'
Font.register({
    family: 'Times-New-Roman',
    fonts: [
        { src: TNR },
        { src: TNRB, fontStyle: 'Bold'},
        { src: TNRBI, fontStyle: 'Bold-Italic'},
        { src: TNRI, fontStyle: 'Italic'}
    ],
})

const styles = StyleSheet.create({
    viewer: {
        width: '100vw',
        height: '100vh'
    },

    pageStyle: {
        backgroundColor: 'white',
        color: 'black',
        padding: '40px',
        fontFamily: 'Times-Roman',
        fontSize: 12,
    },
  });

const A4 = (props) => {

  return (
    <PDFViewer style={styles.viewer}>
        <Document>
            <Page size="A4" style={styles.pageStyle} wrap>
                <View wrap>
                    {props.children}
                </View>
            </Page>
        </Document>
    </PDFViewer>
  )
}

export default A4