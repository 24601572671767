import { redirect, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../helpers'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { EduficLoading, EduficNotFoundIcon } from '../../assets'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const ShortLink = () => {
  const { shortLink } = useParams()
  const [ dialogError, setDialogError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('') 
  
  const optionsGetLink = {
    method: 'get',
    url: `${BASE_API_URL}/short-link/${shortLink}`,
    headers: {
        'Content-Type': 'application/json'
    }
  }

  const getAvailRoom = async () => {
    await axios.request(optionsGetLink)
      .then((response) => {
        window.location.replace(response.data)
      })
      .catch((error) => {
        console.log(error)
        setErrorMessage(error.response.data.message)
        setDialogError(true)
      })
  }

  useEffect(() => {
    getAvailRoom()
  })

  const hideDialogError = () => {
    setDialogError(false)
    setErrorMessage('')
    window.location.replace("https://edufic.id")
  }

  const footerDialogError = (
    <div className='text-center mt-0'>
      <Button label="Ok" onClick={() => hideDialogError()} autoFocus />
    </div>
  )
  
  return (
    <div className='text-center mb-0'>
        <Dialog visible={dialogError} header='Link tidak ditemukan' footer={footerDialogError} 
          onHide={() => hideDialogError()} style={{width:'30rem', margin: '15px'}}>
            <div className='text-center mb-0'>
                {/* <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '6rem', color: 'red' }} /> */}
                <img src={EduficNotFoundIcon} style={{width: '15rem'}}/>
                <p style={{ fontSize: '18px'}}>{errorMessage}</p>
            </div>
        </Dialog>
    </div>
  )
}

export default ShortLink