import React from 'react'
import NavBarLogin from '../Navbar/NavBarLogin'
import styles from './MainContent.module.css'

const MainContent = (props) => {
  return (
    <div className={styles.layout}>
        <NavBarLogin/>
        <div className={styles.layoutMainContent}>{props.children}</div>
    </div>
  )
}

export default MainContent
