import React, { useEffect, useRef, useState } from 'react'
import Page from '../../Layouts/Page/Page'
import styles from './ClassManagement.module.css'
import { FilterMatchMode } from 'primereact/api';
import { Card, Tab, Tabs } from 'react-bootstrap'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { BASE_API_URL, formattedDate, stringToDate } from '../../helpers';
import axios from 'axios';
import { InputNumber } from 'primereact/inputnumber';
import Loading from '../../components/Loading/Loading'
import { Tag } from 'primereact/tag';
import { IconFamily } from '../../assets';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';


const ClassManagement = () => {
  const [ teacherClassList, setTeacherClassList ] = useState(localStorage.getItem('teacherClassList'))
  const teacherID = localStorage.getItem('teacherID')
  const [ listKelasAktif, setListKelasAktif ] = useState(null)
  const [ listKelasSelesai, setListKelasselesai ] = useState(null)
  const [ listKelasBelumSelesai, setListKelasBelumSelesai ] = useState(null)
  const [ listKelasBelumPTC, setListKelasBelumPTC ] = useState(null)
  const [ listKelasBelumRR, setListKelasBelumRR ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ activeKey, setActiveKey ]  = useState(0)
  const listSubject = [
    {
      name: "Adab",
      code: "Adab"
    }, 
    {
      name: "AFQ",
      code: 'AFQ'
    },
    {
      name: "IC Atas",
      code: "IC Atas"
    },
    {
      name: "IC Bawah",
      code: "IC Bawah"
    },
    {
      name: "ICWS - W",
      code: "ICWS - W"
    },
    {
      name: "ICWS - ST",
      code: "ICWS - ST"
    },
    {
      name: "IE",
      code: "IE"
    },
    {
      name: "IL & SoIP",
      code: "IL & SoIP"
    }, 
    {
      name: "NI",
      code: "NI"
    }, 
    {
      name: "QT",
      code: "QT"
    },
    {
      name: "Robotic",
      code: "Robotic"
    }
  ]

  const classType = [
    {
      id: '1-PRV',
      name: 'Private'
    },
    {
      id: '2-SML',
      name: 'Small'
    },
  ]


  const getAllTeacherClass = async (data) => {
    const dataKelas = JSON.stringify({
      idKelas: data
    })

    const options = {
      method: 'post',
      url: `${BASE_API_URL}/classes`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: dataKelas
    }

    axios.request(options)
      .then((response) => {
        const listClass = response.data
        listClass.map((kelas) => {
          kelas.subject = `${kelas.namaKelas} ${kelas.noteSubjek ? "- " + kelas.noteSubjek : ""} `
          return kelas;
        });

        let listClassAktif = listClass.filter((kelas) => (kelas.statusKelas === "Aktif" || kelas.statusKelas === "Belum terverifikasi"))
        let listClassBelumSelesai = listClass.filter((kelas) => kelas.statusKelas === "Belum Diselesaikan")
        let listClassBelumPTC = listClass.filter((kelas) => kelas.statusKelas === "Belum PTC")
        let listClassBelumRR = listClass.filter((kelas) => kelas.statusKelas === "Belum RR")
        let listClassSelesai = listClass.filter((kelas) => kelas.statusKelas === "Selesai")
        
        listClassAktif.map((data, index) => {
          data['index'] = index + 1
          return data;
        })

        listClassSelesai.map((data, index) => {
          data['index'] = index + 1;
          return data;
        })

        setListKelasAktif(listClassAktif)
        setListKelasselesai(listClassSelesai)
        setListKelasBelumPTC(listClassBelumPTC)
        setListKelasBelumRR(listClassBelumRR)
        setListKelasBelumSelesai(listClassBelumSelesai)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getListClass = async (teacherID) => {
    const options = {
      method: 'get',
      url: `${BASE_API_URL}/teachers/${teacherID}`,
      header: {
        "Content-Type": 'application/json'
      }
    }

    axios.request(options)
      .then((response) => {
        if(response.status === 200) {
          localStorage.setItem('teacherClassList', response.data.kelas)
          setTeacherClassList(localStorage.getItem('teacherClassList'))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {  
    getListClass(teacherID)
    getAllTeacherClass(teacherClassList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherClassList])

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  // const [Product, setProduct] = useState(listKelas)
  
  const [rowDialog, setRowDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [submitted, setSubmitted] = useState(false);
  const [ dialogTitle, setDialogTitle ] = useState('Tambah Kelas')
  const [ dialogMessage, setDialogMessage ] = useState(null)
  const [ errorDialog, setErrorDialog ] = useState(false)
  const [nameClass, setNameClass] = useState("")
  const [classLevel, setClassLevel] = useState(null)
  const [classCourseLevel, setClassCourseLevel] = useState(null)
  const [classCode, setClassCode] = useState(null)
  const [batchCode, setBatchCode] = useState(null)
  const [dateTime, setDateTime] = useState(null)
  const [noteSubject, setNoteSubject] = useState(null)
  const [selectedClass, setSelectedClass] = useState(null)
  const [classDetail, setClassDetail] = useState({
    id:null,
    namaKelas: null,
    jadwal: null,
    jenisKelas: null,
  })
 
  const dt = useRef(null)

  const getSeverity = (data) => {
    switch (data) {
      case 'Belum terverifikasi':
        return 'info';
     
      case 'Aktif': 
        return 'warning';
      
      case 'Selesai':
        return 'success';

      case 'Pending':
        return 'primary';

      default:
        return 'danger';
    }
  }

  const refreshTable = async () => {
    await getListClass(teacherID)
    await getAllTeacherClass(teacherClassList)
  }

  const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };

      _filters['global'].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
              <p className={styles.cardTitle}>Daftar Kelas</p>
            </div>
            <div className={styles.searchBarContent}>
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" className={styles.searchBarInput} />
              </span>
              <button className={styles.buttonAddClass} onClick={() => showDialog(null,'Tambah Kelas')}>Kelas +</button>
            </div>
        </div>
    );
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly})
  }

  const showDialog = async (rowData ,title) => {
    if(rowData !== null) {
      setNameClass(rowData.namaKelas)
      setClassLevel(rowData.level)
      setClassCode(rowData.kodeKelas)
      setDateTime(stringToDate(rowData.jadwal))
      setSelectedClass(rowData.jenisKelas)
      setBatchCode(rowData.kodeBatch)
      setClassCourseLevel(rowData.materiKelas)
      setNoteSubject(rowData.noteSubjek)

      setClassDetail({
        id: rowData._id,
        kodeKelas: classCode,
        kodeBatch: batchCode,
        namaKelas: nameClass,
        jadwal: dateTime,
        jenisKelas: selectedClass,
        materiKelas: classCourseLevel,
        noteSubject:  noteSubject
      })
      
      setDialogTitle(title)
      setRowDialog(true)
    }
    else {
      setDateTime(new Date())
      setDialogTitle(title)
      setRowDialog(true)
    } 
  }

  const hideDialog = () => {
    setNameClass("")
    setClassLevel(null)
    setClassCode(null)
    setDateTime(null)
    setDialogTitle('Tambah Kelas')
    setSelectedClass(null)
    setSubmitted(false);
    setRowDialog(false);
    setBatchCode(null)
    setClassCourseLevel(null)
  };

  const hideDialogError = () => {
    setDialogTitle(null)
    setDialogMessage(null)
    setErrorDialog(false)
  }

  const saveButton = async (rowData) => {
    hideDialog()
    setLoading(true)
    if (dialogTitle === 'Edit Kelas') {
      setTimeout(() => {
        updateData(rowData)
      }, 300)
    } else {
      setTimeout(() => {
        addClass(rowData)
      }, 300)
    }
  }

  const updateData = async (rowData) => {
    const data = {
      kodeKelas: classCode,
      kodeBatch: batchCode,
      jenisKelas: selectedClass,
      materiKelas: classCourseLevel,
      namaKelas: nameClass,
      level: classLevel,
      jadwal: stringToDate(dateTime),
      noteSubjek: noteSubject
    }

    const options = {
      method: 'put',
      url: `${BASE_API_URL}/class/${rowData}`,
      header: {
        "Content-Type": 'application/json'
      },
      data: data
    }

    await axios.request(options)
      .then((response) => {
        if(response.status === 200) {
          refreshTable()
        }
      })
      .catch((error) => {
        console.log(error)
      })

    setLoading(false)
  }

  const addClass = async () => {
    const data = {
      kodeKelas: classCode,
      kodeBatch: batchCode,
      jenisKelas: selectedClass,
      namaKelas: nameClass,
      level: classLevel,
      materiKelas: classCourseLevel,
      jadwal: stringToDate(dateTime),
      guru: localStorage.getItem('teacherID'),
      noteSubjek: noteSubject
    }

    const options = {
      method: 'post',
      url: `${BASE_API_URL}/newclass`,
      header: {
        "Content-Type": 'application/json'
      },
      data: data
    }

    await axios.request(options)
      .then((response) => {
        refreshTable()
      })
      .catch((error) => {
        setLoading(false)
        setDialogTitle("Gagal Menambah Kelas")
        setDialogMessage("Kelas gagal ditambahkan karena " + error.response.data.message )
        setErrorDialog(true)
        
      })
    setLoading(false)
  }

  const deleteClass = async (rowData) => {
    hideDeleteDialog()
    setLoading(true)
    const options = {
      method: 'delete',
      url: `${BASE_API_URL}/class/${rowData}`,
      header: {
        'Content-Type': 'application/json'
      }
    }

    await axios.request(options)
      .then((response) => {
        refreshTable()
      })
      .catch((error) => {
        console.log(error)
      })

    refreshTable()

    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  const hideDeleteDialog = () => {
    setNameClass("")
    setClassLevel(null)
    setClassCode(null)
    setDateTime(null)
    setSelectedClass(null)
    setBatchCode(null)
    setClassCourseLevel(null)
    setDeleteDialog(false)
  }

  const productDialogFooter = () => (
    <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} className='mr-3'/>
        <Button label="Save" icon="pi pi-check" onClick={() => saveButton(classDetail.id)}/>
    </React.Fragment>
  );

  const footerDialogError = (
    <div className='text-center mt-0'>
      <Button label="Ok" onClick={() => hideDialogError()} autoFocus />
    </div>
  )

  const deleteDialogFooter = () => (
    <React.Fragment>
      <div className='flex-auto text-center'>
        <Button label="No" icon="pi pi-times" outlined className='mr-3' onClick={hideDeleteDialog}/>
        <Button label="Yes" icon="pi pi-check" severity="danger" onClick={() => deleteClass(classDetail.id)}/>
      </div>
    </React.Fragment> 
  )
  
  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={refreshTable} className={styles.toolsList}/>;
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={() => exportCSV(false)} className={styles.toolsList} />;
  
  const newFormatDate = (rowDate) => {
    const newDate = formattedDate(rowDate.jadwal)
    return newDate;
  }

  const classCodeTemplate = (rowData) => {
    return `${rowData.kodeBatch}.${rowData.kodeKelas}`
  }

  const statusClassTemplate = (rowData) => {
    return <Tag value={rowData.statusKelas} severity={getSeverity(rowData.statusKelas)}></Tag>
  }

  
  const actionBodyTemplate = (rowdata) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className='mr-2' onClick={() => showDialog(rowdata ,'Edit Kelas')}/>
        {/* <Button icon="pi pi-trash" rounded outlined severity='danger' onClick={() => showDeleteDialog(rowdata)}/> */}
        <a href={rowdata.statusKelas === "Belum terverifikasi"? '#' : '/class-management/'+rowdata._id}>
          <Button icon={rowdata.statusKelas === "Belum terverifikasi" ? "pi pi-eye-slash" : "pi pi-eye"} 
           className='ml-2' rounded outlined={rowdata.statusKelas === "Belum terverifikasi" ? false : true} severity='success' 
           tooltip={rowdata.statusKelas === "Belum terverifikasi"? "Menunggu verifikasi" : false}
           tooltipOptions={{hideDelay: 1000}}
           
          />
        </a>
      </React.Fragment>
    )
  }

  return (
    <Page>
        <div className={styles.containerNotif}>
          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Kelas Aktif</p>
              <button onClick={() => setActiveKey(0)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
              <i className='pi pi-sync'></i>
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <p className={styles.cardValue}>{listKelasAktif !== null? listKelasAktif.length : 0} Kelas</p>
              </div>
            </Card.Body>
          </Card>

          <Card  className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Belum PTC</p>
              <button onClick={() => setActiveKey(1)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
          <Card.Body className={styles.cardNotifContent}>
              {/* <i className='pi pi-id-card'></i> */}
              <img src={IconFamily} alt="" />
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <p className={styles.cardValue}>{listKelasBelumPTC !== null? listKelasBelumPTC.length : 0} Kelas</p>
              </div>
            </Card.Body>
          </Card>

          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Belum RR</p>
              <button onClick={() => setActiveKey(2)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
              <i className='pi pi-exclamation-triangle'></i>
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <p className={styles.cardValue}>{listKelasBelumRR !== null? listKelasBelumRR.length : 0} Kelas</p>
              </div>
            </Card.Body>
          </Card>
          
          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Belum Diselesaikan</p>
              <button onClick={() => setActiveKey(3)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
              <i className='pi pi-minus-circle'></i>
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <p className={styles.cardValue}>{listKelasBelumSelesai !== null? listKelasBelumSelesai.length : 0} Kelas</p>
              </div>
            </Card.Body>
          </Card>

          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Kelas Selesai</p>
              <button onClick={() => setActiveKey(4)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
            <i className='pi pi-check'></i>
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <p className={styles.cardValue}>{listKelasSelesai !== null? listKelasSelesai.length : 0} Kelas</p>
              </div>
            </Card.Body>
          </Card>
        </div>

        <Card>
          <Card.Body>
            {/* <Toast ref={toast}/> */}
            <Tabs defaultActiveKey={0} activeKey={activeKey} className={styles.myTabs} onSelect={(k) => setActiveKey(k)}>
              <Tab eventKey={0} title="Kelas Aktif">
                <div>
                  <div className={styles.tableContainer}>
                    <DataTable value={listKelasAktif} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                      rowsPerPageOptions={[5, 10, 25, 50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                      filters={filters} globalFilterFields={['namaKelas', 'subject', '_id', 'jadwal', 'jumlahSiswa', 'jenisKelas', 'level', 'kodeKelas', 'kodeBatch', 'materiKelas']} header={renderHeader()}
                      dataKey='_id' editMode='row' ref={dt} scrollable>
                        <Column field='index' sortable header="No" frozen style={{ minWidth: 'rem', fontWeight:'bold' }}></Column>
                        <Column sortable header="Kode Kelas" frozen style={{ minWidth: '10rem', fontWeight:'bold' }} body={classCodeTemplate}></Column>
                        <Column field='subject' sortable header="Subjek" style={{ minWidth: '14rem' }}></Column>
                        <Column field="level" sortable header="Level" style={{ minWidth: '8rem' }}></Column>
                        <Column field="materiKelas" sortable header="Level Materi" style={{ minWidth: '10rem' }}></Column>
                        <Column field="statusKelas" sortable header="Status" body={statusClassTemplate} style={{ minWidth: '12rem' }}></Column>
                        <Column field="jadwal" sortable header="Jadwal Mulai" body={newFormatDate} style={{ minWidth: '17rem' }}></Column>
                        <Column field='jenisKelas' sortable header="Jenis Kelas" style={{ minWidth: '10rem' }}></Column>
                        <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate} style={{minWidth: '12rem'}} frozen alignFrozen='right'></Column>
                    </DataTable>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={1} title="Belum PTC">
                <div>
                  <div className={styles.tableContainer}>
                    <DataTable value={listKelasBelumPTC} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                      rowsPerPageOptions={[5, 10, 25, 50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                      filters={filters} globalFilterFields={['namaKelas', '_id', 'jadwal', 'jumlahSiswa', 'jenisKelas', 'level', 'kodeKelas', 'kodeBatch', 'materiKelas']} header={renderHeader()}
                      dataKey='_id' editMode='row' ref={dt} scrollable>
                        <Column field='index' sortable header="No" frozen style={{ minWidth: 'rem', fontWeight:'bold' }}></Column>
                        <Column sortable header="Kode Kelas" frozen style={{ minWidth: '10rem', fontWeight:'bold' }} body={classCodeTemplate}></Column>
                        <Column field='subject' sortable header="Subjek" style={{ minWidth: '14rem' }}></Column>
                        <Column field="level" sortable header="Level" style={{ minWidth: '8rem' }}></Column>
                        <Column field="materiKelas" sortable header="Level Materi" style={{ minWidth: '10rem' }}></Column>
                        <Column field="statusKelas" sortable header="Status" body={statusClassTemplate} style={{ minWidth: '12rem' }}></Column>
                        <Column field="jadwal" sortable header="Jadwal Mulai" body={newFormatDate} style={{ minWidth: '17rem' }}></Column>
                        <Column field='jenisKelas' sortable header="Jenis Kelas" style={{ minWidth: '10rem' }}></Column>
                        <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate} style={{minWidth: '12rem'}} frozen alignFrozen='right'></Column>
                    </DataTable>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={2} title="Belum RR">
                <div>
                  <div className={styles.tableContainer}>
                    <DataTable value={listKelasBelumRR} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                      rowsPerPageOptions={[5, 10, 25, 50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                      filters={filters} globalFilterFields={['namaKelas', '_id', 'jadwal', 'jumlahSiswa', 'jenisKelas', 'level', 'kodeKelas', 'kodeBatch', 'materiKelas']} header={renderHeader()}
                      dataKey='_id' editMode='row' ref={dt} scrollable>
                        <Column field='index' sortable header="No" frozen style={{ minWidth: 'rem', fontWeight:'bold' }}></Column>
                        <Column sortable header="Kode Kelas" frozen style={{ minWidth: '10rem', fontWeight:'bold' }} body={classCodeTemplate}></Column>
                        <Column field='subject' sortable header="Subjek" style={{ minWidth: '14rem' }}></Column>
                        <Column field="level" sortable header="Level" style={{ minWidth: '8rem' }}></Column>
                        <Column field="materiKelas" sortable header="Level Materi" style={{ minWidth: '10rem' }}></Column>
                        <Column field="statusKelas" sortable header="Status" body={statusClassTemplate} style={{ minWidth: '12rem' }}></Column>
                        <Column field="jadwal" sortable header="Jadwal Mulai" body={newFormatDate} style={{ minWidth: '17rem' }}></Column>
                        <Column field='jenisKelas' sortable header="Jenis Kelas" style={{ minWidth: '10rem' }}></Column>
                        <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate} style={{minWidth: '12rem'}} frozen alignFrozen='right'></Column>
                    </DataTable>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={3} title="Belum Diselesaikan">
                <div>
                  <div className={styles.tableContainer}>
                    <DataTable value={listKelasBelumSelesai} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                      rowsPerPageOptions={[5, 10, 25, 50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                      filters={filters} globalFilterFields={['namaKelas', '_id', 'jadwal', 'jumlahSiswa', 'jenisKelas', 'level', 'kodeKelas', 'kodeBatch', 'materiKelas']} header={renderHeader()}
                      dataKey='_id' editMode='row' ref={dt} scrollable>
                        <Column field='index' sortable header="No" frozen style={{ minWidth: 'rem', fontWeight:'bold' }}></Column>
                        <Column sortable header="Kode Kelas" frozen style={{ minWidth: '10rem', fontWeight:'bold' }} body={classCodeTemplate}></Column>
                        <Column field='subject' sortable header="Subjek" style={{ minWidth: '14rem' }}></Column>
                        <Column field="level" sortable header="Level" style={{ minWidth: '8rem' }}></Column>
                        <Column field="materiKelas" sortable header="Level Materi" style={{ minWidth: '10rem' }}></Column>
                        <Column field="statusKelas" sortable header="Status" body={statusClassTemplate} style={{ minWidth: '12rem' }}></Column>
                        <Column field="jadwal" sortable header="Jadwal Mulai" body={newFormatDate} style={{ minWidth: '17rem' }}></Column>
                        <Column field='jenisKelas' sortable header="Jenis Kelas" style={{ minWidth: '10rem' }}></Column>
                        <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate} style={{minWidth: '12rem'}} frozen alignFrozen='right'></Column>
                    </DataTable>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={4} title="Kelas Selesai">
                <div>
                  <div className={styles.tableContainer}>
                    <DataTable value={listKelasSelesai} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                      rowsPerPageOptions={[5, 10, 25, 50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                      filters={filters} globalFilterFields={['namaKelas', '_id', 'jadwal', 'jumlahSiswa', 'jenisKelas', 'level', 'kodeKelas', 'kodeBatch', 'materiKelas']} header={renderHeader()}
                      dataKey='_id' editMode='row' ref={dt} scrollable>
                        <Column field='index' sortable header="No" frozen style={{ minWidth: 'rem', fontWeight:'bold' }}></Column>
                        <Column sortable header="Kode Kelas" frozen style={{ minWidth: '10rem', fontWeight:'bold' }} body={classCodeTemplate}></Column>
                        <Column field='subject' sortable header="Subjek" style={{ minWidth: '14rem' }}></Column>
                        <Column field="level" sortable header="Level" style={{ minWidth: '8rem' }}></Column>
                        <Column field="materiKelas" sortable header="Level Materi" style={{ minWidth: '10rem' }}></Column>
                        <Column field="statusKelas" sortable header="Status" body={statusClassTemplate} style={{ minWidth: '12rem' }}></Column>
                        <Column field="jadwal" sortable header="Jadwal Mulai" body={newFormatDate} style={{ minWidth: '17rem' }}></Column>
                        <Column field='jenisKelas' sortable header="Jenis Kelas" style={{ minWidth: '10rem' }}></Column>
                        <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate} style={{minWidth: '12rem'}} frozen alignFrozen='right'></Column>
                    </DataTable>
                  </div>
                </div>
              </Tab>
            </Tabs>
            <Dialog visible={rowDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
                footer={productDialogFooter} onHide={hideDialog} header={dialogTitle}>
              <div className='flex flex-wrap gap-2 p-fluid mb-3'>
                <div className='flex-auto'>
                  <label htmlFor="kodeBatch" className='font-bold block mb-2'>Kode Kelas</label>
                  <div className={styles.classCodeLabel}>
                    <InputNumber 
                      id='kodeBatch' 
                      value={batchCode}
                      useGrouping={false} 
                      onValueChange={(e) => setBatchCode(e.value)} 
                      required 
                      autoFocus 
                      minFractionDigits={0}
                    />
                    <span className={styles.separateDot}>.</span>
                    <InputNumber 
                      id='kodeKelas' 
                      value={classCode} 
                      useGrouping={false} 
                      onValueChange={(e) => setClassCode(e.value)} 
                      required  
                      minFractionDigits={0}
                    />
                  </div>
                </div>
                <div className='flex-auto'>
                  <label htmlFor="tipeKelas" className='font-bold block mb-2'>Type Kelas</label>
                  <Dropdown 
                    id='tipeKelas' 
                    name='tipeKelas'
                    value={selectedClass}
                    options={classType}  
                    optionValue='name' 
                    optionLabel="name" 
                    filter 
                    placeholder="Masukkan tipe kelas" 
                    className='w-full'
                    onChange={(e) => setSelectedClass(e.value)} 
                  />
                </div>
              </div>
              <div className='flex-auto mb-3'>
                <label htmlFor="namaKelas" className='font-bold block mb-2'>Subjek Kelas</label>
                <Dropdown 
                  id='namaKelas' 
                  name='namaKelas'
                  value={nameClass} 
                  options={listSubject} 
                  optionValue='code' 
                  optionLabel='name' 
                  filter
                  placeholder="Choose Subject Class" 
                  className="w-full" 
                  onChange={(e) => setNameClass(e.target.value)}
                />
              </div>
              <div className='flex-auto mb-3'>
                <div className='flex gap-1 align-items-center mb-2'>
                  <label htmlFor="noteSubject" className='font-bold block'>Catatan</label>
                  <Button 
                    id="noteSubject" 
                    severity="help" 
                    outlined 
                    label="?"
                    className="ms-1 p-1 py-0 border-circle text-xs noteSubject"
                  />
                  <Tooltip target=".noteSubject" position='top' className='mx-1'>
                    Data opsional seperti Bahasa pengantar dan catatan penting lainnya, contoh: Full English
                  </Tooltip>
                </div>
                <InputTextarea
                  id='noteSubject'
                  name='noteSubject'
                  value={noteSubject}
                  rows={1}
                  onChange={(e) => setNoteSubject(e.target.value)}
                  className='w-full'
                />
              </div>
              <div className='flex-auto mb-3'>
                <label htmlFor="level" className='font-bold block mb-2'>Level Kelas</label>
                <InputNumber 
                  id='level'
                  name='level' 
                  value={classLevel} 
                  onValueChange={(e) => setClassLevel(e.value)} 
                  className='w-full' 
                  required  
                  min={1} max={20} 
                  minFractionDigits={0} 
                  maxFractionDigits={1}
                />
              </div>
              <div className='flex-auto mb-3'>
                <label htmlFor="materiKelas" className='font-bold block mb-2'>Level Materi</label>
                <InputNumber 
                  id='materiKelas' 
                  name='materiKelas'
                  value={classCourseLevel} 
                  onChange={(e) => setClassCourseLevel(e.value)} 
                  className='w-full' 
                  required  
                  min={1} 
                  max={20} 
                  maxFractionDigits={2} 
                  minFractionDigits={0}
                />
                <label className='font-block mb-2' style={{ fontSize: '0.75rem', color: 'red' }}>Khusus kelas IE (Materi 1a = 1.1/Materi 1b = 1.2)</label>
              </div>

              <div className='flex-auto mb-3'>
                <label htmlFor="jadwalRilis" className='font-bold block mb-2'>Jadwal Mulai</label>
                <Calendar 
                  id='jadwalRilis'
                  name='jadwalRilis'
                  className='w-full' 
                  value={dateTime} 
                  onChange={(e) => setDateTime(e.value)} 
                  showTime 
                  hourFormat='24' 
                  showIcon 
                  dateFormat='dd MM yy -'
                />
              </div>
            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Hapus Kelas" footer={deleteDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content text-center">
                    <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '7rem' }} />
                    <h5>Yakin ingin menghapus kelas "{nameClass}"?</h5>
                </div>
            </Dialog>

            <Loading visible={loading}/>

            <Dialog visible={errorDialog} header={dialogTitle} footer={footerDialogError}
              onHide={() => hideDialogError()} style={{width:'30rem', margin:'10px'}}>
              <div className='text-center mb-0'>
                  <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '6rem', color: 'red' }} />
                  <p style={{ fontSize: '18px'}}>{dialogMessage}</p>
              </div>
            </Dialog>
          </Card.Body>
        </Card>
    </Page>
  )
}

export default ClassManagement