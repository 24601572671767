import React, { useState } from 'react'
import { Form, Card } from 'react-bootstrap'
import styles from './Login.module.css'
import { IconEdufic } from '../../assets'
import Page from '../../Layouts/Page/Page'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { error } from 'jquery'
import { BASE_API_URL, formattedDate, formattedDateWithOutTime } from '../../helpers'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import Loading from '../../components/Loading/Loading'

const Login = () => {
  const [getPassword, setPassword] = useState(false)
  const [user, setUser] = useState({
    email: '',
    password: ''
  })
  const navigate = useNavigate()
  const [ dialogError, setDialogError ] = useState(false)
  const [ dialogErrorMessage, setDialogErrorMessage ] = useState('')
  const [ loading, setLoading ] = useState(false)

  const showPassword = () => {
    setPassword(!getPassword)
  }

  const handleUserInput = (event) => {
    const { name, value } = event.target
    setUser((prevState) => ({
        ...prevState,
        [name]: value
    }))
  }

  const userLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    const formValid = document.querySelector('input')

    if (formValid.validity.valid) {
        const data = JSON.stringify({
            email: user.email,
            password: user.password,
        })

        const options = {
            method: 'post',
            url: `${BASE_API_URL}/teacher/login`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
        }

        await axios.request(options)
            .then((response) => {
                setTimeout(() => {  
                    localStorage.setItem('accessToken', response.data.token)
                    localStorage.setItem('teacherNickName', response.data.teacher.panggilan)
                    localStorage.setItem('teacherEmail', response.data.teacher.email)
                    localStorage.setItem('teacherName', response.data.teacher.nama)
                    localStorage.setItem('teacherPhone', response.data.teacher.nomor)
                    localStorage.setItem('teacherBornDate', formattedDateWithOutTime(response.data.teacher.tanggalLahir))
                    localStorage.setItem('teacherBornPlace', response.data.teacher.tempatLahir)
                    localStorage.setItem('teacherAddress', response.data.teacher.domisili)
                    localStorage.setItem('teacherEducation', response.data.teacher.pendidikan)
                    localStorage.setItem('teacherBank', response.data.teacher.namaBank)
                    localStorage.setItem('teacherBankAccount', response.data.teacher.rekening)
                    localStorage.setItem('teacherID', response.data.teacher._id)
                    localStorage.setItem('teacherClassList', response.data.teacher.kelas)
                    localStorage.setItem('teacherNameBankAccount', response.data.teacher.atasNamaRekening)
                    localStorage.setItem('availableSchedule', response.data.teacher.availableSchedule);
                    if(response.data.teacher !== null) {
                        setTimeout(() => {
                            setLoading(false)
                        }, 300)
                        navigate('/dashboard')
                    }
                }, 500)
            })
            .catch((error) => {
                setLoading(false)
                setDialogErrorMessage(error.response.data.message)
                setTimeout(() => {
                    setDialogError(true)
                }, 200)
            })
    }
  }

  const footerDialogError = (
    <div className='text-center mt-0'>
      <Button label="Ok" onClick={() => setDialogError(false)} autoFocus />
    </div>
  )



  return (
    <Page>
        <div className={styles.wrapper}>
            <div className={styles.inform}>
                <h1 className={styles.title}>
                    Edufic Teacher Portal?
                </h1>
                <br/>
                <p className={styles.paragraph}>
                    Edufic Teacher Portal (ETP) adalah sebuah platform yang digunakan oleh para guru Edufic untuk menunjang proses pembelajaran seperti presensi, 
                    manajemen kelas, sekaligus sarana monitoring dan controling murid. Dengan adanya platform ETP, para guru Edufic diharapkan dapat 
                    terbantu dalam berbagai aktivitas mengajar.
                </p>
            </div>
            <div className={styles.mycontainer}>
                <Card className={styles.mycard}>
                <Card.Body >
                    <Card.Title className='text-center mb-3'>
                        <img 
                        src={IconEdufic} 
                        alt="Icon-Edufic" 
                        height='70'
                        />
                        <h2>Teacher Portal</h2>
                    </Card.Title>
                    <Form id='form-submit' onSubmit={userLogin}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Alamat Email</Form.Label>
                            <Form.Control 
                                type='email' 
                                placeholder="Masukkan Email Aktif Anda"
                                name='email'
                                onChange={handleUserInput}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type= {getPassword ? 'text' : 'password'} 
                                placeholder="Masukkan Password Anda"
                                name='password'
                                onChange={handleUserInput}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check 
                                type="checkbox" 
                                label="Tampilkan kata sandi"  
                                onChange={showPassword}
                            />
                        </Form.Group>
                        <div className='text-center'>
                            <button type='submit' className={styles.mybutton} onClick={userLogin}>
                                Login
                            </button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
            </div>
        </div>
        <Dialog visible={dialogError} header='Gagal Login' footer={footerDialogError} 
          onHide={() => setDialogError(false)} style={{width:'30rem', marginInline:'15px'}}>
            <div className='text-center mb-0'>
                <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '6rem', color: 'red' }} />
                <p style={{ fontSize: '18px'}}>{dialogErrorMessage}</p>
            </div>
        </Dialog>
        <Loading visible={loading}/>
    </Page>
    
  );
}

export default Login
