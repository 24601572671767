import axios from "axios"
import { BASE_API_URL } from ".";

export const getData = async (url, auth = {}) => {
    const option = {
        method: 'get',
        url: `${BASE_API_URL}/${url}`,
        headers: {
            'Authorization': auth,
            'Content-Type': 'application/json',
        }
    }

    const response = await axios.request(option);
    return response;
}

export const postData = async (url, data, auth = {}) => {
    const option = {
        method: 'post',
        url: `${BASE_API_URL}/${url}`,
        headers: {
            'Authorization': auth,
            'Content-Type': 'application/json',
        },
        data: data
    }

    const response = await axios.request(option);
    return response;
}

export const putData = async (url, data, auth = {}) => {
    const option = {
        method: 'put',
        url: `${BASE_API_URL}/${url}`,
        headers: {
            'Authorization': auth,
            'Content-Type': 'application/json',
        },
        data: data
    }

    const response = await axios.request(option);
    return response;
}

export const deleteData = async (url, data, auth = {}) => {
    const option = {
        method: 'delete',
        url: `${BASE_API_URL}/${url}`,
        headers: {
            'Authorization': auth,
            'Content-Type': 'application/json',
        },
        data: data
    }

    const response = await axios.request(option);
    return response;
}