// const BASE_API_URL = "http://54.151.185.58:5000"
// const BASE_API_URL = "http://127.0.0.1:5000"
const BASE_API_URL = "https://be.eduficportal.com"

const formattedDate = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = formattedDate + " - " + formattedTime

    return fullDate
}

const formattedDate2 = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}

const formattedDateWithOutTime = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const fullDate = formattedDate
    return fullDate
}

const formatMonth = (stringDate) => {
    const newDate = new Date(stringDate)
    const formattedDate = newDate.toLocaleString("id-ID", {
        month: "long",
        year: "numeric",
    })

    const fullDate = formattedDate
    return fullDate
}

const stringToDate = (stringDate) => {
    const newDate = new Date(stringDate)
    return newDate
}

const formattedDateWithDay = (date) => {
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}
const getSeverity = (data, dataType) => {
    if (dataType === "attendancePTC") {
        switch (data) {
            case "Hadir":
                return 'success'
            
            case "Belum Diabsen": 
                return 'info'
            
            case "Tidak Hadir":
                return 'danger'

            default:
                return 'info';
        }
    }

    if (dataType === "kelanjutan") {
        switch (data) {
            case "Berhenti":
                return 'danger'
            
            case "Lanjut": 
                return 'success'

            default:
                return 'warning';
        }
    }

    if (dataType === "statusGaji") {
        switch (data) {
            case "Belum dibayar":
                return 'info'
            
            case "Terbayar": 
                return 'success'

            default:
                return 'warning';
        }
    }

    if (dataType === "Salary") {
        switch (data) {
            case 'Terbayar':
                return 'success'
            
            case 'Diangsur':
                return 'warning'
            
            case 'Belum dibayar': 
                return 'danger'
        
            default:
                return 'info';
        }
    }
}

const formatIDRCurrency = (data) => {
    const newData = data.toLocaleString("id-ID", {style:"currency", currency:"IDR"})
    return newData
}

const formatAccounting = (data) => {
    const newData = data.toLocaleString("id-ID", {style:"currency", currency:"IDR"})
    return (newData.slice(2))
}

export { 
    BASE_API_URL, 
    formattedDate, 
    stringToDate, 
    formattedDateWithOutTime, 
    formattedDate2, 
    formattedDateWithDay,
    getSeverity,
    formatIDRCurrency,
    formatAccounting,
    formatMonth
}